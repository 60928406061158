import { memo } from 'react';
import PropTypes from 'prop-types';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { Tooltip } from 'components/common';
import { siNumber } from 'utils/number';

const ThirdPartyTitle = memo(({ profileEmailCount, title = 'Email' }) => {
  if (profileEmailCount <= 0) {
    return <div>{title}</div>;
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ marginRight: '5px' }}>{title}</div>
      <Tooltip
        placement='right'
        title={siNumber(profileEmailCount) + ' 3rd Party Emails'}
      >
        <ExclamationCircleOutlined
          style={{ color: '#faad14', fontSize: '14px' }}
        />
      </Tooltip>
    </div>
  );
});

ThirdPartyTitle.propTypes = {
  title: PropTypes.string,
  profileEmailCount: PropTypes.number,
};

ThirdPartyTitle.defaultProps = {
  title: 'Email',
  profileEmailCount: 0,
};

export default ThirdPartyTitle;
