import styled from 'styled-components';

import { Pane, Table } from 'components/common';

export const TableActions = styled.div`
  justify-content: flex-end;
  display: flex;
  button {
    margin-left: 5px;
  }
`;

export const Container = styled(Pane)`
  margin-top: ${p => p.theme.gutter};
`;

export const StyledTable = styled(Table)`
  margin-top: 10px;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
`;
