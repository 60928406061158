import { useSelector } from 'react-redux';
import { Statistic } from 'components/common';
import { colours } from 'utils/theme';
import { getSearchRequest } from 'selectors/search';

import { Stats } from '../styled';
import ThirdPartyTitle from 'components/Search/Statistics/ThirdParty';

const Statistics = () => {
  const { loading, res } = useSelector(getSearchRequest);
  const {
    totalFunding,
    totalPosters,
    totalPublications,
    totalClinicalTrials,
    profileEmailCount,
    organisationCount,
    countryCount,
    distinctSuperResearchersCount,
  } = res;

  return (
    <Stats>
      <div className='row row1'>
        <Statistic
          icon='award'
          title='Funding Awards'
          iconColor={colours.grants}
          value={loading ? '-' : totalFunding}
        />
        <Statistic
          icon='image'
          title='Tradeshow Sessions'
          iconColor={colours.posters}
          value={loading ? '-' : totalPosters}
        />
        <Statistic
          icon='book'
          title='Published Articles'
          iconColor={colours.publications}
          value={loading ? '-' : totalPublications}
        />
        <Statistic
          icon='trial'
          title='Clinical Trials'
          iconColor={colours.trials}
          value={loading ? '-' : totalClinicalTrials}
        />
      </div>
      <div className='row row2'>
        <Statistic
          icon='email'
          title={
            <ThirdPartyTitle
              profileEmailCount={profileEmailCount}
              title='People'
            />
          }
          value={loading ? '-' : distinctSuperResearchersCount}
        />
        <Statistic
          icon='company'
          title='Organisations'
          value={loading ? '-' : organisationCount}
        />
        <Statistic
          icon='map'
          title='Countries'
          value={loading ? '-' : countryCount}
        />
      </div>
    </Stats>
  );
};

export default Statistics;
