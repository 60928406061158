import { getUserSettingsFromState } from 'components/Settings/UserSettings/userSettingsType';
import { avoidChars, boolValues } from 'models/searchErrorKeys';

export const SLICE = 'userSettings';

export const getShouldReload = state => state[SLICE].reload;
export const getUserSetting = (state, userSetting) => state[SLICE][userSetting];

export const getUserSettings = state => {
  let retState = [];
  for (var prop in getUserSettingsFromState) {
    if (state[SLICE][prop] !== 'undefined') {
      retState.push({
        userSettingType: getUserSettingsFromState[prop],
        value: state[SLICE][getUserSettingsFromState[prop]],
      });
    }
  }
  return retState;
};

export const performBoolSearch = (
  slice,
  filterGrp = null,
  filterType = null
) => state => {
  let term = null;
  if (filterType !== null) {
    term = state[slice]?.[filterType][filterGrp]?.term;
  } else {
    term =
      filterGrp != null
        ? state[slice]?.filters[filterGrp]?.term
        : state[slice]?.filters?.term;
    if (!term && filterGrp) {
      term = state[slice]?.[filterGrp]?.term;
    }
  }

  if (term && state[SLICE][getUserSettingsFromState.DisplayBoolErrorMessage]) {
    let hasOperators = boolValues.some(x =>
      term?.toLowerCase().includes(x.toLowerCase())
    );
    if (hasOperators) {
      let splitTerms = [];
      let splitByWord = term.split(' ').filter(x => x !== '');
      splitByWord.forEach(x => {
        let lastSplit = 0;
        for (let i = 0; i < x.length; i++) {
          if (avoidChars.includes(x[i])) {
            let char = x[i];
            let fetchedWord = x.slice(lastSplit, i);
            if (fetchedWord.length && fetchedWord.length > 1) {
              splitTerms.push(fetchedWord);
            }
            lastSplit = i + 1;
            splitTerms.push(char);
          }
        }
        if (lastSplit < x.length) splitTerms.push(x.slice(lastSplit));
      });
      let nonCapitalOperators = splitTerms
        .filter(y => boolValues.includes(y.toUpperCase()))
        .every(y => boolValues.includes(y));
      if (!nonCapitalOperators) return !nonCapitalOperators;
    }
  }
  return false;
};

export const performPhraseSearch = (
  slice,
  filterGrp = null,
  filterType = null
) => state => {
  let ret = false;
  let term = null;
  if (filterType !== null) {
    term = state[slice]?.[filterType][filterGrp]?.term;
  } else {
    term =
      filterGrp != null
        ? state[slice]?.filters[filterGrp]?.term
        : state[slice]?.filters?.term;
    if (!term && filterGrp) {
      term = state[slice]?.[filterGrp]?.term;
    }
  }

  if (
    term &&
    state[SLICE][getUserSettingsFromState.DisplayPhraseErrorMessage]
  ) {
    let splitTerms = [];
    term = term.replace(/[“”]/g, '"');
    let splitByWord = term.split(' ').filter(x => x !== '');
    splitByWord.forEach(x => {
      let lastSplit = 0;
      for (let i = 0; i < x.length; i++) {
        if (avoidChars.includes(x[i])) {
          let char = x[i];
          let fetchedWord = x.slice(lastSplit, i);
          if (fetchedWord.length && fetchedWord.length > 1) {
            splitTerms.push(fetchedWord);
          }
          lastSplit = i + 1;
          splitTerms.push(char);
        }
      }
      if (lastSplit < x.length) splitTerms.push(x.slice(lastSplit));
    });
    for (let i = 0; i < splitTerms.length - 1; i++) {
      // Ensures that current value is a word, that isnt bool
      if (
        !avoidChars.includes(splitTerms[i]) &&
        !boolValues.includes(splitTerms[i].toUpperCase())
      ) {
        let wordCount = 1;
        let firstValue = i;
        while (
          i < splitTerms.length - 1 &&
          !boolValues.includes(splitTerms[i + 1].toUpperCase()) &&
          !avoidChars.includes(splitTerms[i + 1])
        ) {
          wordCount++;
          i++;
        }
        if (wordCount > 1) {
          if (firstValue === 0 || i === splitTerms.length - 1) {
            return true;
          }
          if (
            !(splitTerms[firstValue - 1] === '"' && splitTerms[i + 1] === '"')
          ) {
            return true;
          }
        }
      }
    }
  }

  return ret;
};
