import { types } from 'actions/userSettings';

const defaultState = {
  displayBoolErrorMessage: true,
  displayPhraseErrorMessage: true,
};

const REDUCER = {
  [types.SET_USER_SETTING]: (state, action) => {
    const { filter, value } = action.payload;
    return {
      ...state,
      [filter]: value,
    };
  },
  [types.RESTORE_STATE]: (state, action) => {
    if (!action.payload) {
      return {
        ...defaultState,
        ...state,
        restoringState: false,
      };
    }

    let restoredState = JSON.parse(JSON.stringify(action.payload));
    restoredState = {
      ...defaultState,
      ...restoredState,
    };
    restoredState.restoringState = false;
    return restoredState;
  },
};

const reducer = (state = defaultState, action) => {
  const handler = REDUCER[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
