import { useEffect } from 'react';
import { Modal, notification } from 'antd';
import { useSelector } from 'react-redux';

import {
  getActiveList,
  getAreAllMembersSelected,
  getSelectedMemberIds,
  getSelectedMembers,
} from 'selectors/lists';
import { usePost } from 'hooks';
import { exportMembers } from 'services/api';
import { ewrcaProfileListNotification } from '../Members/notifications';
import { views } from '../constants';

const Export = ({ onClose, type = views.researchers }) => {
  const activeList = useSelector(getActiveList);
  const selectedMemberIds = useSelector(getSelectedMemberIds);
  const selectedMembers = useSelector(getSelectedMembers);
  const allSelected = useSelector(getAreAllMembersSelected);
  const [{ res: exportRes, error, loading }, postExport] = usePost({
    url: exportMembers(activeList.id, type),
  });

  useEffect(() => {
    if (exportRes) {
      onClose();
      notification.open({
        message: 'Generating Export',
        description:
          'We are generating your list export and will notify you when it is ready.',
        placement: 'bottomLeft',
      });
    }
  }, [exportRes, onClose]);

  useEffect(() => {
    if (error) {
      onClose();
      if (error.status === 412) {
        notification.open(ewrcaProfileListNotification);
        return;
      }

      notification.open({
        message: 'Cannot Process',
        description: 'Failed to export',
        placement: 'bottomLeft',
      });
    }
  }, [error, onClose]);

  const handleExport = () => {
    if (allSelected) {
      postExport();
      return;
    }

    if (selectedMembers.all) {
      postExport({
        excludedFromAllMemberIds: Object.keys(selectedMembers.exclude).map(
          x => +x
        ),
        excludedFromAllResearcherIds: Object.keys(selectedMembers.exclude),
      });
    } else {
      postExport({
        researcherIds: selectedMemberIds,
        memberIds: selectedMemberIds,
      });
    }
  };

  const allOrSelectedText = allSelected ? 'all' : 'the selected';

  return (
    <Modal
      open
      title={`Export ${activeList.name}`}
      onCancel={onClose}
      onOk={handleExport}
      okText='Yes'
      okButtonProps={{ disabled: loading }}
      cancelButtonProps={{ disabled: loading }}
    >
      Would you like to export {allOrSelectedText} members of {activeList.name}?
    </Modal>
  );
};

export default Export;
