import { Button } from 'components/common';
import { useAuth } from 'hooks';
import licenseType from 'models/licenseType';

const Export = ({ onExport, canExport }) => {
  const { hasLicense } = useAuth();

  const hasLicensetoExport = hasLicense(
    [
      licenseType.temporaryOld,
      licenseType.legacy,
      licenseType.academic,
      licenseType.industry,
    ],
    false
  );
  if (!hasLicensetoExport) return null;

  return (
    <Button size='small' disabled={!canExport} onClick={onExport}>
      Export Data
    </Button>
  );
};

export default Export;
