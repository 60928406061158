import { useState } from 'react';

import TokenInput from '../../TokenInput';
import Select from '../../Select';
import Button from '../../Button';

import { options } from './helpers';
import { AddUserContainer } from './styled';

const AddUser = ({ tableData, setTableData, list, allUsers, loading }) => {
  const [toAddPermission, setToAddPermission] = useState();
  const [usersToAdd, setUsersToAdd] = useState([]);

  const addUsers = () => {
    const toAdd = usersToAdd.map(u => ({
      userId: u.legacyUserId,
      userName: u.fullname,
      accessLevel: toAddPermission,
      listId: list ? list.id : null,
    }));

    const previousData = tableData || [];

    setTableData([...previousData, ...toAdd]);
    setUsersToAdd([]);
  };

  const disabled = allUsers && allUsers.length === 0;

  return (
    <AddUserContainer>
      <TokenInput
        placeholder={loading ? 'Loading...' : 'Search for users'}
        value={usersToAdd}
        onChange={setUsersToAdd}
        options={allUsers}
        displayKey='fullname'
        optionKey='legacyUserId'
        onTheFly={false}
        disabled={disabled}
      />
      <Select
        options={options}
        value={toAddPermission}
        onChange={setToAddPermission}
        placeholder='Access Level'
        disabled={disabled}
      />
      <Button disabled={!toAddPermission || disabled} onClick={addUsers}>
        Add
      </Button>
    </AddUserContainer>
  );
};

export default AddUser;
