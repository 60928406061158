import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import { Logo } from 'components/common';

import SetNewPass from './SetNewPass';
import { Container, Pane, InnerContent } from '../styled';

const SetPassword = () => {
  const { search } = useLocation();
  const { code, isTrial } = qs.parse(search);

  return (
    <Container>
      <Logo />
      <Pane>
        <InnerContent>
          <SetNewPass code={code} isTrial={isTrial} />
        </InnerContent>
      </Pane>
    </Container>
  );
};

export default SetPassword;
