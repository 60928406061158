import { useSelector } from 'react-redux';
import { getActiveList } from 'selectors/lists';
import { Select } from './styled';

import listRenderOption from 'components/common/Lists/ListRenderOptions';

const Selector = ({ disabled, lists, onSelectorChange }) => {
  const activeList = useSelector(getActiveList);
  const dataTestId = 'select-list';

  return (
    <Select
      options={lists}
      disabled={disabled}
      placeholder={disabled ? 'Loading...' : 'Select a list'}
      value={disabled ? 'Loading...' : activeList.id}
      onChange={onSelectorChange}
      filterOption={(input, option) =>
        option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      renderOption={(o, i) => listRenderOption({ ...o, dataTestId }, i)}
      showSearch
      style={{ maxWidth: '50vw' }}
    />
  );
};

export default Selector;
