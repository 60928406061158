import dayjs from 'dayjs';
import {
  ActivitySearch,
  OrganisationSearch,
  ResearcherSearch,
} from 'components/Search/Results/helpers';
import {
  categoriesAcademicList,
  categoriesIndustryList,
  categoriesCollectionsHealthcare,
  categoriesCollectionsTools,
  categoriesCollectionsFinancialServices,
  categoriesCollectionsOther,
  categoriesCollectionsBioinformatics,
} from 'services/inputTokens';
import { format } from 'utils/date';
import { DistinctBy } from './array';
import {
  acquisitionOnlyKeys,
  fundingKeys,
  ventureCapitalKeys,
} from 'components/Search/Filters/keys';

// Generic formatters
export const capitalise = str =>
  str.length > 0 ? `${str[0].toUpperCase()}${str.slice(1)}` : str;
const quotesOr = value => value.map(v => `"${v}"`).join(' OR ');
const wildcardOr = value => value.map(v => `*${v}*`).join(' OR ');
const mixedOr = value => value.map(v => `"*${v}*"`).join(' OR ');
const minNumberFormat = (key, value) => `${key}:>=${value}`;
const maxNumberFormat = (key, value) => `${key}:<=${value}`;
const numberRangeFormat = (minKey, maxKey) => (key, value, filters) => {
  const minValue = filters[minKey];
  const maxValue = filters[maxKey];
  if (!minValue && !maxValue) return '';
  return `${key}:[${minValue || 0} TO ${(maxValue === 'Uncapped'
    ? null
    : maxValue) || '*'}]`;
};

const defaultQuotes = (key, value) =>
  value.length ? `${key}:(${quotesOr(value)})` : '';

const defaultInteger = (key, value) => (value > 0 ? `${key}:${value}` : '');

const defaultMixed = (key, value) =>
  value.length ? `${key}:(${mixedOr(value)})` : '';

const dateRange = (key, value) => {
  const from = format(value[0], 'YYYY-MM-DD');
  const to = format(value[1], 'YYYY-MM-DD');

  return `${key}:[${from} TO ${to}]`;
};

const quickDateRange = (key, value, filters) => {
  const dateFilter = movingDateRange(key, value);

  if (dateFilter && filters?.category?.profile) {
    return `(${dateFilter} OR Category:Profile)`;
  }

  return dateFilter;
};

const movingDateRange = (key, value) => {
  let from;
  let to = 'now/d';
  if (value === 'all') return '';
  if (value === 'custom') return '';
  if (Array.isArray(value)) {
    from = format(value[0], 'YYYY-MM-DD');
    to = format(value[1], 'YYYY-MM-DD');
  } else {
    if (value === 'one') {
      from = 'now/d-1M';
    }

    if (value === 'six') {
      from = 'now/d-6M';
    }

    if (value === 'twelve') {
      from = 'now/d-1y';
    }

    if (value === 'twentyfour') {
      from = 'now/d-2y';
    }

    if (value === 'thirtysix') {
      from = 'now/d-3y';
    }
  }
  return `${key}:[${from} TO ${to}]`;
};

const customDateRange = selector => (key, value, filters) => {
  if (filters[selector] !== 'custom') {
    return '';
  }

  const from = value[0];
  const to = value[1];

  return `${key}:[${format(from, 'YYYY-MM-DD')} TO ${format(
    to,
    'YYYY-MM-DD'
  )}]`;
};

const multiCheckbox = (key, value) => {
  const selected = Object.entries(value).filter(([k, v]) => !!v);
  if (!selected.length) return '';
  const asText = selected
    .map(([k]) => capitalise(k))
    .map(x => `"${x}"`)
    .join(' OR ');

  return `${key}:(${asText})`;
};

// Geo formatters
const orgGeoFormat = (key, value) => {
  const continents = [
    'africa',
    'asia',
    'europe',
    'north america',
    'south america',
    'oceania',
    'antarctica',
  ];
  const regions = [];
  const countries = [];
  const superOrganisation = key === 'SuperOrganisationGeography';
  const researcherLocation = key === 'Country';

  const regionFilter = () => {
    if (superOrganisation) return 'SuperOrganisationLocationRegion';
    if (researcherLocation) return 'Region';

    return 'Region';
  };

  const countryFilter = () => {
    if (superOrganisation) return 'SuperOrganisationLocationCountry';
    if (researcherLocation) return 'Country';

    return 'Country';
  };

  value.forEach(v =>
    continents.includes(v.toLowerCase()) ? regions.push(v) : countries.push(v)
  );
  const query = [];
  if (regions.length) {
    query.push(`${[regionFilter()]}:(${quotesOr(regions)})`);
  }
  if (countries.length) {
    query.push(`${countryFilter()}:(${quotesOr(countries)})`);
  }

  return `(${query.join(' OR ')})`;
};

const orgCategoryFormat = (key, value) => {
  let base = '';
  let valueToFilter = [];
  if (value.length === 0) {
    return base;
  }

  let i = 0;
  while (i < value.length) {
    if (value[i] === '') {
      return base;
    } else if (value[i] === 'Academic (All)') {
      let j = 0;
      while (j < categoriesAcademicList.length) {
        valueToFilter.push(categoriesAcademicList[j]);
        j++;
      }
    } else if (value[i] === 'Industry (All)') {
      let j = 0;
      while (j < categoriesIndustryList.length) {
        valueToFilter.push(categoriesIndustryList[j]);
        j++;
      }
    } else if (value[i] === 'Healthcare') {
      let j = 0;
      while (j < categoriesCollectionsHealthcare.length) {
        valueToFilter.push(categoriesCollectionsHealthcare[j]);
        j++;
      }
    } else if (value[i] === 'Tools') {
      let j = 0;
      while (j < categoriesCollectionsTools.length) {
        valueToFilter.push(categoriesCollectionsTools[j]);
        j++;
      }
    } else if (value[i] === 'Financial Services') {
      let j = 0;
      while (j < categoriesCollectionsFinancialServices.length) {
        valueToFilter.push(categoriesCollectionsFinancialServices[j]);
        j++;
      }
    } else if (value[i] === 'Other') {
      let j = 0;
      while (j < categoriesCollectionsOther.length) {
        valueToFilter.push(categoriesCollectionsOther[j]);
        j++;
      }
    } else if (value[i] === 'Bioinformatics') {
      let j = 0;
      while (j < categoriesCollectionsBioinformatics.length) {
        valueToFilter.push(categoriesCollectionsBioinformatics[j]);
        j++;
      }
    } else if (value[i] === 'Analytical Instruments / Life Science Tools') {
      valueToFilter.push('Analytical Instruments');
      valueToFilter.push('Life Science Tools');
    } else {
      valueToFilter.push(value[i]);
    }

    i++;
  }

  base = defaultQuotes(key, valueToFilter);
  return base;
};

const orgCategoryGroupFormat = (key, value) => {
  let base = '';
  base = defaultQuotes(key, value);
  return base;
};

const recentlyMovedOrg = (key, value) =>
  value ? `OrganisationStartDate:[now-6M TO now]` : '';

const hasClinicalTrials = (key, value) =>
  value ? `HasClinicalTrials:true` : '';

const minSponsoredTrials = (key, value) =>
  value ? `SuperOrgTotalSponsorCount:>=${value}` : '';

const emailWildcard = (key, value) =>
  value.length ? `Email.keyword:(${wildcardOr(value)})` : '';

// Funding formatters
const openGrantFormat = (key, value) =>
  value ? `FundingEnds:[${format(dayjs().toJSON(), 'YYYY-MM-DD')} TO *]` : '';

const earlyAwardDateFormat = (key, value) => {
  const today = format(dayjs().toJSON(), 'YYYY-MM-DD');
  return value
    ? `CurrentBudgetStart:[* TO ${today}] AND FundingStarts:[${today} TO *]`
    : '';
};

const grantExpiryFormat = (key, value) => {
  return value ? `FundingEnds:[now TO now+3M]` : '';
};

// Tradeshow formatters
const tradeshowFormat = (key, value, filters) => {
  if (filters.exhibitingShow && filters.presentingAtTradeshow) {
    return `(TradeShowsAttended:(${quotesOr(
      value.map(v => v.label)
    )}) OR ExhibitedItems.Show:(${quotesOr(value.map(v => v.label))}))`;
  } else if (filters.exhibitingShow)
    return `ExhibitedItems.Show:(${quotesOr(value.map(v => v.label))})`;

  const _key = filters.thisShowOnly
    ? 'TradeShowAttended.keyword'
    : 'TradeShowsAttended';

  return `${_key}:(${quotesOr(value.map(v => v.label))})`;
};

const sessionTypeFormat = (key, value, filters) => {
  const _key = filters.thisSessionTypeOnly
    ? 'SessionType'
    : 'TradeShowSessionTypes';

  return `${_key}:(${quotesOr(value)})`;
};

// Advanced formatters
const namedAuthorFormat = (key, value) => {
  const selected = Object.entries(value).filter(([k, v]) => !!v);
  const conditions = {
    firstAuthor: 'FirstNamedAuthor:true',
    middleAuthor: '(FirstNamedAuthor:false AND LastNamedAuthor:false)',
    lastAuthor: 'LastNamedAuthor:true',
  };

  return `(${selected.map(([k, v]) => conditions[k]).join(' OR ')})`;
};

const journalFormat = (key, value) =>
  value.length ? `Journal.keyword:(${quotesOr(value)})` : '';

const superOrganisationFoundedYearFormat = (key, value) => {
  if (!value) return '';

  const foundedYear = dayjs()
    .subtract(Math.abs(value), 'year')
    .toISOString();

  if (value > 0) {
    return `SuperOrganisationFoundedYear:[* TO ${format(foundedYear, 'YYYY')}]`;
  }

  return `SuperOrganisationFoundedYear:[${format(foundedYear, 'YYYY')} TO *]`;
};

// formatters
const superOrganisationSizeFormat = (key, value) => {
  if (!value) return '';
  let query = '';
  value.forEach(selectedValue => {
    const valuesArray = selectedValue.split('-');
    if (valuesArray.length === 2) {
      const superOrganisationSizeRangeLowerBound = valuesArray[0].trim();
      const superOrganisationSizeRangeUpperBound = valuesArray[1].trim();
      query += `${query ? ' OR ' : ''} (${minNumberFormat(
        'SuperOrganisationSizeRangeLowerBound',
        superOrganisationSizeRangeLowerBound
      )} AND ${maxNumberFormat(
        'SuperOrganisationSizeRangeUpperBound',
        superOrganisationSizeRangeUpperBound
      )})`;
    } else {
      const minSize = selectedValue.replace('+', '').trim();
      query += `${query ? ' OR ' : ''} ${minNumberFormat(
        'SuperOrganisationSizeRangeLowerBound',
        minSize
      )}`;
    }
  });
  return query ? `(${query})` : '';
};

const timeAtCompanyFormat = (key, value) => {
  let query = '';
  value.forEach(selectedValue => {
    const isYear = selectedValue.includes('year');
    const isMax = selectedValue.includes('>');
    let numberParseIndex = isYear
      ? selectedValue.indexOf('y') - 1
      : selectedValue.indexOf('m') - 1;
    if (isMax) {
      const number = +selectedValue
        .slice(0, numberParseIndex)
        .split('>')[1]
        .trim();
      query += `${
        query ? ' OR ' : ''
      } OrganisationStartDate:[now-100y TO now-${number}y]`;
    } else {
      const numbers = selectedValue.slice(0, numberParseIndex).split('-');
      const from = +numbers[0].trim();
      const to = +numbers[1].trim();
      if (isYear) {
        query += `${
          query ? ' OR ' : ''
        } OrganisationStartDate:[now-${to}y TO now-${from}y]`;
      } else {
        query += `${
          query ? ' OR ' : ''
        } OrganisationStartDate:[now-${to}M TO now-${from}M]`;
      }
    }
  });
  return query ? `(${query})` : '';
};

const organisationDescription = (key, value) => {
  if (value?.length > 0) {
    let valueOr = `${value.map(v => `"${v}"`).join(' OR ')}`;
    return `(SuperOrganisationDescription:(${valueOr}) OR SuperOrganisationDescriptions.DescriptionValue:(${valueOr}))`;
  }
};

const acquisitionMade = (key, value, filter) => {
  if (filter.showAcquiredCompanies && filter.showAcquiringCompanies) {
    if (filter.acquirers?.length) {
      return 'SuperOrganisationAcquisitions.AcquisitionMade:false';
    }
    return 'SuperOrganisationAcquisitions:*';
  }
  if (filter.showAcquiringCompanies) {
    return 'SuperOrganisationAcquisitions.AcquisitionMade:true';
  }
  if (filter.showAcquiredCompanies) {
    return 'SuperOrganisationAcquisitions.AcquisitionMade:false';
  }
  return '';
};

export const stateFormatter = (key, value) => {
  if (value?.length) {
    return `${key}:(${DistinctBy(
      value.map(v => `"${v.stateName || v}"`),
      x => x
    ).join(' OR ')})`;
  }
  return '';
};

const recentlyAddedOrg = (key, value) =>
  value ? `SuperOrganisationFirstDiscoverableDate:[now/d-30d TO now/d]` : '';

const queryFormatter = {
  default: defaultQuotes,
  defaultInteger: defaultInteger,
  // Geo
  category: multiCheckbox,
  organisationGeography: orgGeoFormat,
  superOrganisationGeography: orgGeoFormat,
  country: orgGeoFormat,
  organisationCategory: orgCategoryFormat,
  organisationCategoryGroup: orgCategoryGroupFormat,
  organisationName: defaultMixed,
  email: emailWildcard,
  date: quickDateRange,
  recentlyMovedOrg: recentlyMovedOrg,
  superOrganisationSize: superOrganisationSizeFormat,
  superOrganisationFoundedYear: superOrganisationFoundedYearFormat,
  // superOrganisationRevenue: numberRangeFormat('minRevenue', 'maxRevenue'),
  jobTitle: defaultQuotes,
  // Funding
  dateAwarded: dateRange,
  fundingStarts: dateRange,
  fundingEnds: dateRange,
  openGrant: openGrantFormat,
  earlyAwardDate: earlyAwardDateFormat,
  threeYearTotalFunding: numberRangeFormat(
    fundingKeys.minFunding.key,
    fundingKeys.maxFunding.key
  ),
  grantExpiry: grantExpiryFormat,
  // Tradeshows
  tradeshow: tradeshowFormat,
  sessionType: sessionTypeFormat,
  // Clinical Trials
  startDate: dateRange,
  hasClinicalTrials: hasClinicalTrials,
  minSponsoredTrials: minSponsoredTrials,
  phaseStartDate: dateRange,
  // Advanced
  totalPublications: minNumberFormat,
  totalCollaboratorsOverall: minNumberFormat,
  totalFirstNamedAuthorCount: minNumberFormat,
  totalLastNamedAuthorCount: minNumberFormat,
  totalGrants: minNumberFormat,
  namedAuthor: namedAuthorFormat,
  threeYearHIndexAverage: minNumberFormat,
  workItemCategories: (_, value) => multiCheckbox('Category', value),

  //organisation
  timeAtCompany: timeAtCompanyFormat,
  journal: journalFormat,
  announcedDateSelection: movingDateRange,
  announcedDatePicked: customDateRange(
    ventureCapitalKeys.announcedDateSelection.key
  ),
  fundingRoundAmounts: numberRangeFormat(
    ventureCapitalKeys.minFundingAmount.key,
    ventureCapitalKeys.maxFundingAmount.key
  ),
  totalFundingRaised: numberRangeFormat(
    ventureCapitalKeys.minTotalFundingRaised.key,
    ventureCapitalKeys.maxTotalFundingRaised.key
  ),
  acquisitionPrice: numberRangeFormat(
    acquisitionOnlyKeys.minAcquisitionPrice.key,
    acquisitionOnlyKeys.maxAcquisitionPrice.key
  ),
  acquisitionDateSelection: movingDateRange,
  acquisitionDatePicked: customDateRange(
    acquisitionOnlyKeys.acquisitionDateSelection.key
  ),
  acquisitionMade,
  superOrganisationLocationState: stateFormatter,
  state: stateFormatter,
  researcherLocationState: stateFormatter,
  organisationDescription,
  recentlyAddedOrg,
};

const termFormatter = {
  lists: value => value,
  excludeLists: value => value,
  organisationLists: value => value,
  organisationExcludeLists: value => value,
};

export const noValue = value =>
  !value ||
  (Array.isArray(value) && value.length === 0) ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'object' &&
    Object.values(value).filter(v => !!v).length === 0);

const queryKeyMap = new Map();
queryKeyMap.set(
  ventureCapitalKeys.fundingType.key,
  'SuperOrganisationLatestFundingRound.InvestmentType'
);
queryKeyMap.set(
  ventureCapitalKeys.investors.key,
  'SuperOrganisationLatestFundingRound.ElasticInvestors.Name.keyword'
);
queryKeyMap.set(
  'fundingRoundAmounts',
  'SuperOrganisationLatestFundingRound.RaisedAmountUsd'
);
queryKeyMap.set('totalFundingRaised', 'SuperOrganisationTotalFundingUsd');
queryKeyMap.set(
  ventureCapitalKeys.announcedDateSelection.key,
  'SuperOrganisationLatestFundingRound.AnnouncedDate'
);
queryKeyMap.set(
  ventureCapitalKeys.announcedDatePicked.key,
  'SuperOrganisationLatestFundingRound.AnnouncedDate'
);

const queryKeyNestedFRMap = new Map();
queryKeyNestedFRMap.set(
  ventureCapitalKeys.fundingType.key,
  'SuperOrganisationFundingRounds.InvestmentType'
);
queryKeyNestedFRMap.set(
  ventureCapitalKeys.investors.key,
  'SuperOrganisationFundingRounds.ElasticInvestors.Name.keyword'
);
queryKeyNestedFRMap.set(
  'fundingRoundAmounts',
  'SuperOrganisationFundingRounds.RaisedAmountUsd'
);
queryKeyNestedFRMap.set(
  'fundingRoundAmounts',
  'SuperOrganisationFundingRounds.RaisedAmountUsd'
);
queryKeyNestedFRMap.set(
  ventureCapitalKeys.announcedDateSelection.key,
  'SuperOrganisationFundingRounds.AnnouncedDate'
);
queryKeyNestedFRMap.set(
  ventureCapitalKeys.announcedDatePicked.key,
  'SuperOrganisationFundingRounds.AnnouncedDate'
);

const queryKeyNestedAcqMap = new Map();
queryKeyNestedAcqMap.set(
  acquisitionOnlyKeys.acquirers.key,
  'SuperOrganisationAcquisitions.OrganisationName.keyword'
);
queryKeyNestedAcqMap.set(
  acquisitionOnlyKeys.acquisitionType.key,
  'SuperOrganisationAcquisitions.AcquisitionType'
);
queryKeyNestedAcqMap.set(
  acquisitionOnlyKeys.acquisitionDatePicked.key,
  'SuperOrganisationAcquisitions.AcquiredOn'
);
queryKeyNestedAcqMap.set(
  acquisitionOnlyKeys.acquisitionDateSelection.key,
  'SuperOrganisationAcquisitions.AcquiredOn'
);
queryKeyNestedAcqMap.set(
  'acquisitionPrice',
  'SuperOrganisationAcquisitions.PriceUsd'
);
queryKeyNestedAcqMap.set(
  'acquisitionMade',
  'SuperOrganisationAcquisitions.AcquisitionMade'
);

const queryKeyNestedSuperOrgSponsorMap = new Map();
queryKeyNestedSuperOrgSponsorMap.set(
  'sponsorSuperOrgId',
  'SuperOrganisationSponsors.SuperOrgId'
);

function getKeyFromMap(key, map) {
  const storedKey = map.get(key);
  return storedKey || key;
}

export const buildQuery = (
  filters,
  view,
  prefixFilter,
  useResearcherFields = false
) => {
  const sponsorSuperOrgKeys = ['sponsorSuperOrgId'];
  const baseSkipKeys = [
    'term',
    'thisShowOnly',
    'minFunding',
    'maxFunding',
    'kol',
    'thisSessionTypeOnly',
    'latestFundingRoundsOnly',
    // 'minRevenue',
    // 'maxRevenue',
  ];

  const highlightSkipKeys = [
    ...baseSkipKeys,
    'category',
    'region',
    'country',
    'organisationCategory',
    'workItemCategories',
    'date',
  ];
  let fundingRoundKeys = [
    ventureCapitalKeys.fundingType.key,
    ventureCapitalKeys.announcedDateSelection.key,
    ventureCapitalKeys.announcedDatePicked.key,
    ventureCapitalKeys.investors.key,
    ventureCapitalKeys.minFundingAmount.key,
    ventureCapitalKeys.maxFundingAmount.key,
    ventureCapitalKeys.minTotalFundingRaised.key,
    ventureCapitalKeys.maxTotalFundingRaised.key,
  ];

  let acquisitionKeys = [
    acquisitionOnlyKeys.showAcquiredCompanies.key,
    acquisitionOnlyKeys.showAcquiringCompanies.key,
    acquisitionOnlyKeys.acquirers.key,
    acquisitionOnlyKeys.acquisitionType.key,
    acquisitionOnlyKeys.minAcquisitionPrice.key,
    acquisitionOnlyKeys.maxAcquisitionPrice.key,
    acquisitionOnlyKeys.acquisitionDatePicked.key,
    acquisitionOnlyKeys.acquisitionDateSelection.key,
  ];

  const skipFRKeys = [
    ventureCapitalKeys.minFundingAmount.key,
    ventureCapitalKeys.maxFundingAmount.key,
    ventureCapitalKeys.minTotalFundingRaised.key,
    ventureCapitalKeys.maxTotalFundingRaised.key,
  ];

  const skipAcqKeys = [
    acquisitionOnlyKeys.showAcquiredCompanies.key,
    acquisitionOnlyKeys.showAcquiringCompanies.key,
    acquisitionOnlyKeys.minAcquisitionPrice.key,
    acquisitionOnlyKeys.maxAcquisitionPrice.key,
  ];
  const hiddenKeys = ['fundingRoundAmounts'];

  const hiddenAcqKeys = ['acquisitionPrice', 'acquisitionMade'];

  const latestFundingRoundsOnly = filters && filters['latestFundingRoundsOnly'];

  let orgKeys = [
    'superOrganisationSize',
    'superOrganisationFoundedYear',
    'exhibitingShow',
    'presentingAtShow',
  ];

  const fundingRoundLatestKeys = [
    'fundingType',
    'announcedDateSelection',
    'announcedDatePicked',
    'investors',
    'minFundingAmount',
    'maxFundingAmount',
  ];

  const orgToSuperOrgMapper = {
    city: 'superOrganisationLocationCity',
    state: 'superOrganisationLocationState',
    organisationGeography: 'superOrganisationGeography',
  };

  const researcherKeysToRemove = ['sessionType'];

  const termsKeys = [
    'lists',
    'excludeLists',
    'organisationLists',
    'organisationExcludeLists',
  ];

  if (latestFundingRoundsOnly) {
    orgKeys = orgKeys.concat([...fundingRoundLatestKeys]);
    fundingRoundKeys = fundingRoundKeys.filter(f =>
      fundingRoundLatestKeys.includes(f)
    );
  }

  const terms = {};
  let _filters = { ...filters };

  if (ResearcherSearch(view) || ActivitySearch(view) || useResearcherFields) {
    orgKeys.forEach(k => delete _filters[k]);
  } else if (OrganisationSearch(view)) {
    researcherKeysToRemove.forEach(k => delete _filters[k]);

    for (const key in orgToSuperOrgMapper) {
      Object.assign(_filters, {
        [orgToSuperOrgMapper[key]]: _filters[key],
      });
      delete _filters[key];
    }
  }

  const buildParsedQuery = skipKeys => {
    return Object.entries(_filters).reduce((acc, [key, value]) => {
      let skip = skipKeys;

      if (key === 'phaseStartDate' && !_filters.hasOwnProperty('phase')) {
        return acc;
      }

      if (latestFundingRoundsOnly) {
        skip = [
          ...skip,
          ...skipFRKeys,
          ...acquisitionKeys,
          ...sponsorSuperOrgKeys,
        ];
      } else {
        skip = [
          ...skip,
          ...fundingRoundKeys,
          ...acquisitionKeys,
          ...sponsorSuperOrgKeys,
        ];
      }

      if (skip.includes(key) || noValue(value)) return acc;
      if (termsKeys.includes(key)) {
        terms[key] = termFormatter[key](value);
        return acc;
      }

      const format = queryFormatter[key] || queryFormatter.default;
      return [
        ...acc,
        format(capitalise(getKeyFromMap(key, queryKeyMap)), value, _filters),
      ];
    }, []);
  };

  const parsed = buildParsedQuery(baseSkipKeys);
  const parsedHighlight = buildParsedQuery(highlightSkipKeys);

  if (!_filters.term) _filters.term = !prefixFilter ? '*' : '';
  const termWithoutWeirdQuotes = _filters.term
    .replace(/“/g, '"')
    .replace(/”/g, '"');

  let hiddenFields = ['threeYearTotalFunding', 'totalFundingRaised'];

  if (latestFundingRoundsOnly) {
    hiddenFields = [...hiddenFields, ...hiddenKeys];
  }

  const parsedHidden = hiddenFields.map(key => {
    const format = queryFormatter[key] || queryFormatter.default;
    return format(capitalise(getKeyFromMap(key, queryKeyMap)), null, _filters);
  });

  const query = [
    prefixFilter,
    termWithoutWeirdQuotes ? `(${termWithoutWeirdQuotes})` : '',
    ...parsed,
    ...parsedHidden,
  ]
    .filter(v => !!v)
    .join(' AND ');

  const highlightQuery = [
    prefixFilter,
    termWithoutWeirdQuotes ? `(${termWithoutWeirdQuotes})` : '',
    ...parsedHighlight,
    ...parsedHidden,
  ]
    .filter(v => !!v)
    .join(' AND ');

  const nestedQueries = [];

  const superOrgSponsorQueryValues = Object.entries(_filters)
    .reduce((acc, [key, value]) => {
      if (sponsorSuperOrgKeys.includes(key)) {
        const format = queryFormatter.defaultInteger;
        return [
          ...acc,
          format(
            capitalise(getKeyFromMap(key, queryKeyNestedSuperOrgSponsorMap)),
            value,
            _filters
          ),
        ];
      }
      return acc;
    }, [])
    .filter(v => v);

  if (superOrgSponsorQueryValues.length > 0) {
    nestedQueries.push({
      path: 'SuperOrganisationSponsors',
      query: superOrgSponsorQueryValues.join(' AND '),
    });
  }

  if (OrganisationSearch(view) || ActivitySearch(view)) {
    let filteredFRKeys = fundingRoundKeys.filter(k => !skipFRKeys.includes(k));

    if (latestFundingRoundsOnly) {
      filteredFRKeys = fundingRoundLatestKeys.filter(
        k => !fundingRoundLatestKeys.includes(k)
      );
    }

    const fundingRoundQueryValues = Object.entries(_filters)
      .reduce((acc, [key, value]) => {
        if (filteredFRKeys.includes(key)) {
          const format = queryFormatter[key] || queryFormatter.default;
          return [
            ...acc,
            format(
              capitalise(getKeyFromMap(key, queryKeyNestedFRMap)),
              value,
              _filters
            ),
          ];
        }
        return acc;
      }, [])
      .concat(
        (latestFundingRoundsOnly ? [] : hiddenKeys).map(key => {
          const format = queryFormatter[key] || queryFormatter.default;
          return format(
            capitalise(getKeyFromMap(key, queryKeyNestedFRMap)),
            null,
            _filters
          );
        })
      )
      .filter(v => v);

    if (fundingRoundQueryValues.length > 0) {
      nestedQueries.push({
        path: 'SuperOrganisationFundingRounds',
        query: fundingRoundQueryValues.join(' AND '),
      });
    }

    const filteredAcquisitionKeys = acquisitionKeys.filter(
      k => !skipAcqKeys.includes(k)
    );
    const acquisitionQueryValues = Object.entries(_filters)
      .reduce((acc, [key, value]) => {
        if (filteredAcquisitionKeys.includes(key)) {
          const format = queryFormatter[key] || queryFormatter.default;
          return [
            ...acc,
            format(
              capitalise(getKeyFromMap(key, queryKeyNestedAcqMap)),
              value,
              _filters
            ),
          ];
        }
        return acc;
      }, [])
      .concat(
        hiddenAcqKeys.map(key => {
          const format = queryFormatter[key] || queryFormatter.default;
          return format(
            capitalise(getKeyFromMap(key, queryKeyNestedAcqMap)),
            null,
            _filters
          );
        })
      )
      .filter(v => v);

    if (acquisitionQueryValues.length) {
      nestedQueries.push({
        path: 'SuperOrganisationAcquisitions',
        query: acquisitionQueryValues.join(' AND '),
      });
    }
  }
  return [query, highlightQuery, terms, nestedQueries];
};
