import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getActiveFilters, getFilter, getSearchView } from 'selectors/search';
import {
  countries,
  states,
  organisationEmployeeCount,
  organisationFoundationYear,
  organisationsTypeahead,
} from 'services/inputTokens';
import { Select as AntSelect } from 'antd';
import { Filter } from '../helpers';
import FilterGroup from './FilterGroup';
import { geoOrganisationKeys, geoResearcherKeys } from './keys';
import { OrganisationSearch } from '../Results/helpers';
import { tagRender } from 'components/common/TagRender';

const { Option } = AntSelect;

export const validateEmployeeCount = value => {
  if (!value) {
    return false;
  }

  const trimmedValue = value.trim();

  const rangePattern = /^(\d+)\s*-\s*(\d+)$/;
  const match = trimmedValue.match(rangePattern);
  if (match) {
    const leftValue = +match[1];
    const rightValue = +match[2];

    return leftValue < rightValue;
  }

  if (trimmedValue.endsWith('+')) {
    const number = trimmedValue.slice(0, -1);
    const parsedNumber = +number;

    if (!isNaN(parsedNumber)) {
      return parsedNumber >= 0;
    }
  }

  const parsedNumber = +trimmedValue;
  if (!isNaN(parsedNumber)) {
    return parsedNumber >= 0;
  }

  return false;
};

const OrgFilters = ({ activeOnly, loading }) => {
  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 0.5, paddingRight: '10px' }}>
          <Filter
            filterType='token'
            filterId={geoOrganisationKeys.superOrganisationSize.key}
            placeholder='Employee Count'
            helpText='Filter results by the number of employees at an organisation'
            options={organisationEmployeeCount}
            activeOnly={activeOnly}
            onTheFly={false}
            readOnly={true}
            validator={validateEmployeeCount}
            disabled={loading}
          />
        </div>
        <Filter
          style={{ flex: 0.5, marginLeft: 'auto' }}
          filterType='select'
          filterId={geoOrganisationKeys.superOrganisationFoundedYear.key}
          placeholder='Year Founded'
          allowClear
          showSearch
          options={organisationFoundationYear}
          activeOnly={activeOnly}
          disabled={loading}
        />
      </div>
      {/*<Filter*/}
      {/*  filterType='number'*/}
      {/*  filterId='minRevenue'*/}
      {/*  formatter={formatCurrency}*/}
      {/*  placeholder='Min. Revenue (Yearly)'*/}
      {/*  step={10000}*/}
      {/*  min={0}*/}
      {/*  helpText='Filter results by the minimum yearly revenue for an organisation'*/}
      {/*/>*/}
      {/*<Filter*/}
      {/*  filterType='number'*/}
      {/*  filterId='maxRevenue'*/}
      {/*  formatter={formatCurrency}*/}
      {/*  placeholder='Max. Revenue (Yearly)'*/}
      {/*  step={10000}*/}
      {/*  min={0}*/}
      {/*  helpText='Filter results by the maximum yearly revenue for an organisation'*/}
      {/*/>*/}
    </>
  );
};

const Geo = ({ loading }) => {
  const isOrgView = OrganisationSearch(useSelector(getSearchView));
  const statesFilter = useSelector(getFilter('state'));

  const activeFilters = useSelector(
    getActiveFilters(
      isOrgView
        ? Object.keys(geoOrganisationKeys)
        : Object.keys(geoResearcherKeys)
    )
  );

  const renderOrgNames = (o, i) => (
    <Option
      title={o}
      key={`${o}-${i}`}
      value={o}
      data-testid={`$org-category-option-${i}`}
      details={o}
    >
      {o}
    </Option>
  );

  return (
    <FilterGroup
      icon='map'
      title='Organisation / Geography'
      activeKeys={activeFilters}
      sectionKey='geo'
    >
      <>
        <Filter
          filterType='token'
          filterId={geoOrganisationKeys.organisationGeography.key}
          placeholder='Region/Country'
          options={countries}
          disabled={loading}
        />
        <Filter
          filterType='token'
          placeholder='Cities'
          filterId={geoOrganisationKeys.city.key}
          disabled={loading}
        />
        <Filter
          filterType='select'
          placeholder='Organisations'
          mode={'multiple'}
          style={{ width: '100%' }}
          renderOption={renderOrgNames}
          tagRender={tagRender}
          showSearch={true}
          allowClear={false}
          filterId={geoOrganisationKeys.organisationName.key}
          options={organisationsTypeahead}
          disabled={loading}
        />
        <Filter
          filterType='token'
          placeholder='States'
          options={states}
          filterId={geoOrganisationKeys.state.key}
          displayKey='displayName'
          onTheFly={false}
          otherRemoteParams={{
            excludeStateIds:
              statesFilter?.map(x => x?.id)?.filter(x => x) ?? [],
          }}
          disabled={loading}
        />
        <Filter
          filterType='token'
          placeholder='Zip / Postcodes'
          filterId={geoOrganisationKeys.postcode.key}
          disabled={loading}
        />
        <Filter
          filterType='token'
          placeholder='Email Domains - Max 10'
          filterId={geoOrganisationKeys.email.key}
          tokenLimit={10}
          disabled={loading}
        />
        <Filter
          filterType='token'
          placeholder='Last Name'
          filterId={geoOrganisationKeys.lastName.key}
          disabled={loading}
        />
        {isOrgView && <OrgFilters loading={loading} />}
        <Filter
          filterType='token'
          placeholder='Job title'
          filterId={geoResearcherKeys.jobTitle.key}
          disabled={loading}
        />
        <Filter
          filterType='token'
          placeholder='Organisation Description'
          filterId={geoOrganisationKeys.organisationDescription.key}
          disabled={loading}
        />
        {isOrgView && (
          <Filter
            filterType='checkbox'
            filterId={geoOrganisationKeys.recentlyAddedOrg.key}
            disabled={loading}
          >
            Added within last month
          </Filter>
        )}
        <Filter
          filterType='checkbox'
          filterId={geoOrganisationKeys.recentlyMovedOrg.key}
          disabled={loading}
        >
          Moved organisation within last six months
        </Filter>
      </>
    </FilterGroup>
  );
};

Geo.propTypes = {
  loading: PropTypes.bool,
};

export default Geo;
