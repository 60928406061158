export const getListsRequest = state => state.listsRequest;
export const getActiveList = state => state.activeList;
export const getShowMyListsOnly = state => state.showMyListsOnly;
export const getIncludePausedLists = state => state.includePausedLists;
export const getActiveModal = state => state.modal;
export const getSelectedMembers = state => state.selectedMembers;
export const getPageNumber = state => state.pageNumber;
export const getPageSize = state => state.pageSize;
export const getTableSort = state => state.tableSort;
export const getAddToTarget = state => state.addToTarget;
export const getListMembers = state => state.listMembers;
export const getRestoringState = state => state.restoringState;
export const getView = state => state.view;
