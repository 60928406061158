import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { countries, states, timeAtCompany } from 'services/inputTokens';
import styled from 'styled-components';
import OrgFilterField from './OrgFilterField';
import { resetFilters, setFilterGroup } from 'actions/organisation';
import { Button } from 'components/common';
import { getFilter } from 'selectors/organisation';

const Content = styled.div`
  padding: 0px 0px 10px;
  > * {
    margin-bottom: 10px;
    :last-child {
      margin-bottom: 5px;
    }
  }
`;

export const Actions = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
`;

const OrganisationResearchersFilter = ({
  organisationId,
  onFilterUpdate,
  loading,
}) => {
  const dispatch = useDispatch();

  const handleSearch = () => {
    onFilterUpdate();
  };

  const handleReset = () => {
    dispatch(resetFilters(organisationId));
  };

  const statesFilter = useSelector(getFilter(organisationId, 'state'));
  return (
    <>
      <Content>
        <OrgFilterField
          filterType='token'
          filterId='country'
          organisationId={organisationId}
          placeholder='Region/Country'
          options={countries}
          setFilterGroup={setFilterGroup}
          getFilter={getFilter}
          disabled={loading}
        />
        <OrgFilterField
          filterType='token'
          placeholder='Cities'
          filterId='city'
          organisationId={organisationId}
          setFilterGroup={setFilterGroup}
          getFilter={getFilter}
          disabled={loading}
        />
        <OrgFilterField
          filterType='token'
          placeholder='States'
          options={states}
          filterId='state'
          organisationId={organisationId}
          setFilterGroup={setFilterGroup}
          getFilter={getFilter}
          displayKey={'displayName'}
          onTheFly={false}
          otherRemoteParams={{
            excludeStateIds:
              statesFilter?.map(x => x?.id)?.filter(x => x) ?? [],
          }}
          disabled={loading}
        />
        <OrgFilterField
          filterType='token'
          filterId='jobTitle'
          placeholder='Job Title'
          organisationId={organisationId}
          setFilterGroup={setFilterGroup}
          getFilter={getFilter}
          disabled={loading}
        />
        <OrgFilterField
          filterType='token'
          filterId='lastName'
          placeholder='Last Name'
          organisationId={organisationId}
          setFilterGroup={setFilterGroup}
          getFilter={getFilter}
          disabled={loading}
        />
        <OrgFilterField
          filterType='token'
          options={timeAtCompany}
          filterId='timeAtCompany'
          placeholder='Time at Company'
          organisationId={organisationId}
          setFilterGroup={setFilterGroup}
          getFilter={getFilter}
          disabled={loading}
        />
      </Content>
      <Actions>
        <Button
          data-testid='filter-reset'
          onClick={handleReset}
          disabled={loading}
        >
          Reset
        </Button>
        <Button
          onClick={handleSearch}
          type='primary'
          style={{ marginLeft: '10px' }}
          data-testid='filter-update'
          disabled={loading}
        >
          Update
        </Button>
      </Actions>
    </>
  );
};

OrganisationResearchersFilter.propTypes = {
  organisationId: PropTypes.number.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default OrganisationResearchersFilter;
