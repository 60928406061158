import React, { useState, useMemo } from 'react';

import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { OrgAboutContainer, WrappedDescription } from '../styled';
import CrunchbaseLogo from 'components/common/CrunchbaseLogo';

function partition(array, isValid) {
  return array.reduce(
    ([pass, fail], elem) => {
      return isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]];
    },
    [[], []]
  );
}

const AboutDescription = ({ description }) => {
  if (!description) return null;
  return (
    <WrappedDescription>
      <h3>
        {description.desciptionType === 'ShortSummary' ? 'Brief' : 'Full'}{' '}
        Description
      </h3>
      {description.scrapedSource === 'Crunchbase' && <CrunchbaseLogo />}
      <p className={'about'}>{description.descriptionValue}</p>
    </WrappedDescription>
  );
};

const About = ({ descriptions }) => {
  // const maxAboutLength = 340;
  const [showMoreClicked, setShowMoreClicked] = useState(false);
  const shortSummaries = useMemo(
    () => descriptions.filter(desc => desc.desciptionType === 'ShortSummary'),
    [descriptions]
  );
  let [shortSummariesPDL, shortSummariesRest] = partition(
    shortSummaries,
    e => e.scrapedSource === 'PDL'
  );
  const longSummaries = useMemo(
    () => descriptions.filter(desc => desc.desciptionType === 'LongSummary'),
    [descriptions]
  );

  if (!shortSummaries && !longSummaries) return null;
  const hasShowMoreLink = !(longSummaries.length === 0);

  const leadShortSummary = () => {
    if (!shortSummaries) return;

    // Get PDL descriptions first, if nothing matches return the first description we have
    if (shortSummariesPDL[0]) return shortSummariesPDL[0];

    const firstSummary = shortSummariesRest[0];
    shortSummariesRest.splice(0, 1);
    return firstSummary;
  };

  const onShowMoreOrLessClicked = () => {
    setShowMoreClicked(!showMoreClicked);
  };

  const shortSummary = leadShortSummary();

  return (
    <OrgAboutContainer>
      {shortSummary && <AboutDescription description={shortSummary} />}
      {showMoreClicked &&
        shortSummariesRest.map((summary, index) => (
          <AboutDescription
            key={`${summary.scrapedSource}-SHORT-${index}`}
            description={summary}
          />
        ))}
      {showMoreClicked &&
        longSummaries.map((summary, index) => (
          <AboutDescription
            key={`${summary.scrapedSource}-LONG-${index}`}
            description={summary}
          />
        ))}
      {hasShowMoreLink && (
        <Button
          onClick={
            !showMoreClicked ? onShowMoreOrLessClicked : onShowMoreOrLessClicked
          }
          icon={!showMoreClicked ? <DownOutlined /> : <UpOutlined />}
        >
          {!showMoreClicked ? 'View More' : 'View Less'}
        </Button>
      )}
    </OrgAboutContainer>
  );
};

export default About;
