import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import QRCodeStyling from 'qr-code-styling';
import { Alert } from 'antd';

import { useAuth } from 'hooks';
import { Input, Form, Button, MobileStoreButton } from 'components/common';

import TfaEnrollmentMessage from './TfaEnrollmentMessage';

const qrCode = new QRCodeStyling({
  width: 250,
  height: 250,
  type: 'svg',
  shape: 'shape',
  dotsOptions: {
    color: '#000000',
    type: 'rounded',
  },
  imageOptions: {
    margin: 0,
  },
});

const iosURL = 'https://apps.apple.com/us/app/google-authenticator/id388497605';
const androidURL =
  'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';

const CodeForm = ({ code, setCode, isLoading, handleVerifyCode }) => {
  const {
    user: { tfaCode, userEmail, tfaActivated },
  } = useAuth();
  const qrCodeEl = useRef(null);
  const ref = useRef(null);

  useEffect(() => {
    if (!tfaActivated) {
      const url = `otpauth://totp/SciLeads:${encodeURIComponent(
        userEmail
      )}?secret=${tfaCode}&issuer=SciLeads`;
      qrCode.update({
        data: url,
      });
      qrCode.append(qrCodeEl.current);
    }
  }, [tfaActivated, userEmail, tfaCode]);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  const openChat = () => {
    const { fcWidget } = window;
    if (!fcWidget?.isOpen()) {
      fcWidget?.open();
    }
  };

  return (
    <>
      {!tfaActivated && (
        <>
          <div className='enrollment-required'>2FA Enrollment Required</div>
          <div ref={qrCodeEl} />
          <Alert
            type='info'
            message={<TfaEnrollmentMessage onMoreClick={openChat} />}
          />
          <div style={{ textAlign: 'center' }}>
            <MobileStoreButton store='ios' url={iosURL} />
            <MobileStoreButton store='android' url={androidURL} />
          </div>
        </>
      )}
      <Form onSubmit={handleVerifyCode}>
        <Input
          ref={ref}
          disabled={isLoading}
          onChange={setCode}
          value={code}
          placeholder={!tfaActivated ? 'Enter generated 2FA Code' : '2FA Code'}
        />
      </Form>
      <Button
        disabled={isLoading || !code}
        onClick={handleVerifyCode}
        type='primary'
      >
        Submit
      </Button>
    </>
  );
};

CodeForm.propTypes = {
  code: PropTypes.string.isRequired,
  setCode: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  handleVerifyCode: PropTypes.func.isRequired,
};

CodeForm.defaultProps = {
  isLoading: false,
};

export default CodeForm;
