//@ts-check
import { Button, Tooltip, Icon } from 'components/common';
import { DisabledButtonWrapper } from 'components/common/styled';
import { useAuth, usePost } from 'hooks';
import licenseType from 'models/licenseType';
import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { handleExportNotification } from 'reducers/notifications';
import {
  getFiltersByGroupId,
  getOrganisationResearchers,
  getOrganisationResearcherSearchError,
  getOrganisationWorkItemSearchError,
  getOrganisationResearchersLoading,
  getOrganisationView,
  getOrganisationWorkItemFilters,
  getOrganisationWorkItemIsLoading,
  getOrganisationWorkItems,
  getBlockedForResearchersExport,
  getBlockedForWorkItemExport,
  getOrganisationClinicalTrialData,
  getBlockedForClinicalTrialExport,
  getOrganisationClinicalTrialFilters,
  getOrganisationClinicalTrialSearchError,
} from 'selectors/organisation';
import { exportSearch, largeWarning } from 'services/api';
import styled from 'styled-components';
import { buildQuery } from 'utils/search';
import {
  ORGANISATIONCLINICALTRIALSVIEW,
  ORGANISATIONPEOPLEVIEW,
  ORGANISATIONWORKITEMVIEW,
} from '../helpers';
import { theme } from '../../../utils/theme';

const DownloadButtonContainer = styled.div`
  margin-left: auto;
  margin-top: auto;
  padding-top: 10px;
  margin-right: 10px;
`;

/***
 * @param {{organisation: {id: number}}} props
 */
export default function OrganisationDownloadButton({ organisation }) {
  // @ts-ignore
  const [{ res: exportRes, loading: loadingExport }, postExport] = usePost({});
  const { hasLicense, user } = useAuth();

  /***
   * @type {string}
   */
  const view = useSelector(getOrganisationView);
  const loadingResearchers = useSelector(
    getOrganisationResearchersLoading(organisation.id)
  );
  const loadingWorkItems = useSelector(
    getOrganisationWorkItemIsLoading(organisation.id)
  );
  const loadingClinicalTrials = useSelector(
    getOrganisationWorkItemIsLoading(organisation.id)
  );
  const organisationResearchers = useSelector(
    getOrganisationResearchers(organisation.id)
  );
  const organisationWorkItems = useSelector(
    getOrganisationWorkItems(organisation.id)
  );
  const organisationClinicalTrials = useSelector(
    getOrganisationClinicalTrialData(organisation.id)
  );
  const researcherFilters = useSelector(getFiltersByGroupId(organisation.id));
  const workItemFilters = useSelector(
    getOrganisationWorkItemFilters(organisation.id)
  );
  const clinicalTrialFilters = useSelector(
    getOrganisationClinicalTrialFilters(organisation.id)
  );
  const searchError = useSelector(
    getOrganisationResearcherSearchError(organisation.id)
  );
  const workItemSearchError = useSelector(
    getOrganisationWorkItemSearchError(organisation.id)
  );
  const clinicalTrialSearchError = useSelector(
    getOrganisationClinicalTrialSearchError(organisation.id)
  );
  const hasNonDemoIndustryLicense = hasLicense(
    [licenseType.industry, licenseType.academic],
    false
  );
  const researcherDownloadBlocked = useSelector(
    getBlockedForResearchersExport(organisation.id)
  );
  const workItemDownloadBlocked = useSelector(
    getBlockedForWorkItemExport(organisation.id)
  );
  const clinicalTrialDownloadBlocked = useSelector(
    getBlockedForClinicalTrialExport(organisation.id)
  );

  const getPrefixFilter = view => {
    if (view === ORGANISATIONCLINICALTRIALSVIEW)
      return `Category:ClinicalTrials`;

    return `SuperOrganisationId:${organisation.id}`;
  };

  const exportHandler = () => {
    const [query, , terms, nestedQueries] = buildQuery(
      filters(),
      null,
      getPrefixFilter(view),
      true
    );

    // @ts-ignore
    postExport({
      url: ewrca() ? largeWarning('UniversalSearch') : exportSearch,
      body: {
        query: query,
        nestedQueries: nestedQueries.length > 0 ? nestedQueries : null,
        orderBy: 'desc',
        filters: { ...terms },
        orgPageExportId: organisation.id,
        isOrganisationSponsorExport: view === ORGANISATIONCLINICALTRIALSVIEW,
      },
    });
  };

  const Help = () => (
    <Tooltip
      title='Export provides all contacts and organisations associated with clinical trials on this page'
      placement='right'
    >
      <div style={{ cursor: 'pointer', paddingRight: '5px' }}>
        <Icon icon='help' color={theme.teal} size='xs' />
      </div>
    </Tooltip>
  );

  const filters = () => {
    if (view === ORGANISATIONPEOPLEVIEW) return researcherFilters;
    if (view === ORGANISATIONWORKITEMVIEW) return workItemFilters;
    if (view === ORGANISATIONCLINICALTRIALSVIEW) return clinicalTrialFilters;
  };

  const showDownloadButton =
    (view === ORGANISATIONPEOPLEVIEW ||
      view === ORGANISATIONWORKITEMVIEW ||
      view === ORGANISATIONCLINICALTRIALSVIEW) &&
    !user.isRateLimited;

  const ewrca = () => {
    if (view === ORGANISATIONPEOPLEVIEW) return organisationResearchers?.ewrca;
    if (view === ORGANISATIONWORKITEMVIEW) return organisationWorkItems?.ewrca;
    if (view === ORGANISATIONCLINICALTRIALSVIEW)
      return organisationClinicalTrials?.ewrca;
  };

  const currentPanelSearchError = () => {
    if (view === ORGANISATIONPEOPLEVIEW) return searchError;
    if (view === ORGANISATIONWORKITEMVIEW) return workItemSearchError;
    if (view === ORGANISATIONCLINICALTRIALSVIEW)
      return clinicalTrialSearchError;
  };

  const currentPanelExportBlocked = () => {
    if (view === ORGANISATIONPEOPLEVIEW) return researcherDownloadBlocked;
    if (view === ORGANISATIONWORKITEMVIEW) return workItemDownloadBlocked;
    if (view === ORGANISATIONCLINICALTRIALSVIEW)
      return clinicalTrialDownloadBlocked;
  };

  useEffect(() => {
    if (exportRes) {
      handleExportNotification(ewrca());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportRes]);

  if (
    !(
      view === ORGANISATIONPEOPLEVIEW ||
      ORGANISATIONWORKITEMVIEW ||
      ORGANISATIONCLINICALTRIALSVIEW
    ) ||
    !hasNonDemoIndustryLicense
  ) {
    return null;
  }

  const exportButton = (
    <div style={{ display: 'flex' }}>
      {view === ORGANISATIONCLINICALTRIALSVIEW && <Help />}
      <Button
        size='small'
        disabled={
          loadingExport ||
          loadingResearchers ||
          loadingWorkItems ||
          loadingClinicalTrials ||
          currentPanelSearchError() ||
          (view === ORGANISATIONPEOPLEVIEW &&
            (organisationResearchers?.count === 0 ||
              researcherDownloadBlocked)) ||
          (view === ORGANISATIONWORKITEMVIEW &&
            (organisationWorkItems?.totalCount === 0 ||
              workItemDownloadBlocked)) ||
          (view === ORGANISATIONCLINICALTRIALSVIEW &&
            (organisationClinicalTrials?.totalCount === 0 ||
              clinicalTrialDownloadBlocked))
        }
        onClick={exportHandler}
      >
        Export Data
      </Button>
    </div>
  );

  return (
    <DownloadButtonContainer>
      {showDownloadButton && currentPanelExportBlocked() && (
        <Tooltip
          placement='topRight'
          title='You need to update the search to reactivate export'
        >
          <DisabledButtonWrapper>{exportButton}</DisabledButtonWrapper>
        </Tooltip>
      )}
      {showDownloadButton && !currentPanelExportBlocked() && exportButton}
    </DownloadButtonContainer>
  );
}
