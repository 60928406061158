import { types } from 'actions/users';

const defaultState = {
  reload: false,
};

const REDUCER = {
  [types.RELOAD_USERS]: (state, action) => {
    return {
      ...state,
      reload: action.payload.reload,
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = REDUCER[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
