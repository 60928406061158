import { Fragment, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { useAuth } from 'hooks';
import { Button, Icon, Input, Tooltip } from 'components/common';

import TablePopover from 'components/common/TablePopover';
import ListTokens from './ListTokens/ListTokens';

import {
  setFiltersFromAlert,
  setExternalSearchFromSavedSearch,
  setExternalSearch,
  setView,
} from 'actions/search';
import {
  setAlertFilter,
  setAlertToDelete,
  setAlertToEdit,
} from 'actions/alerts';
import {
  getAlertToEdit,
  getAlertToDelete,
  getAlertsFilter,
} from 'selectors/alerts';
import { frequencyOptions, frequencyEnums } from 'models/frequency';
import { nonEditableAlertSearchExcludedFilters, tokenise } from 'utils/filters';
import { searchOrigin, view } from 'components/Search/Results/constants';
import { views } from 'components/Lists/constants';

import { DeleteModal, EditModal } from './Modal';
import { TableActions, StyledTable } from './styled';
import { tablePerPage } from './helpers';
import { RecentResults } from './TablePopover/RecentResults';
import licenseType from 'models/licenseType';
import { GetSearchKeysByView } from 'components/Search/Filters/keys';

const HoverDiv = styled.div`
  display: flex;
  padding-top: 2px;
  justify-content: center;
  align-content: center;
  font-size: 12px;
  font-weight: 600;
`;

const BorderDiv = styled.div`
  border: 2px solid ${p => p.color};
  border-radius: ${p => (p.radius ? p.radius : '100%')};
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InfoItem = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
`;

const SavedAlerts = ({ data, setData, loading }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const ref = useRef();
  const { user } = useAuth();
  const [alerts, setAlerts] = useState([]);
  const editAlert = useSelector(getAlertToEdit);
  const deletedAlert = useSelector(getAlertToDelete);
  const researchFields = GetSearchKeysByView(searchOrigin.researcherSearch);
  const orgFields = GetSearchKeysByView(searchOrigin.organisationSearch);
  const alertsFilter = useSelector(getAlertsFilter);
  const { hasLicense } = useAuth();

  const hasOrgLicense = hasLicense(
    [licenseType.industry, licenseType.academic],
    true
  );

  const config = {
    headers: [
      {
        label: 'New',
        key: 'recentItemsResults',
        minWidth: 50,
      },
      {
        label: 'Search Name',
        key: 'name',
        minWidth: 100,
        maxContentWidth: 300,
      },
      {
        label: 'Term',
        key: 'monthlyKeywords',
        minWidth: 50,
        maxContentWidth: 50,
      },
      {
        label: 'Filters',
        key: 'filters',
        minWidth: 120,
      },
      {
        label: 'Included Lists',
        key: 'lists',
        minWidth: 120,
        maxContentWidth: 120,
      },
      {
        label: 'Excluded Lists',
        key: 'excludeLists',
        minWidth: 120,
        maxContentWidth: 120,
      },
      {
        label: ' ',
        key: 'key',
        minWidth: 50,
      },
      { label: 'Alert Frequency', key: 'frequency', minWidth: 50 },
      { testId: 'actions', minWidth: 150 },
    ],
    createRow: (r, i) => {
      const frequency = frequencyOptions.find(
        op => op.enumValue === frequencyEnums[r.frequency]
      );

      const parsedSearch = r.structuredSearch
        ? JSON.parse(r.structuredSearch)
        : null;

      const tokens = parsedSearch
        ? tokenise(
            parsedSearch,
            r.searchOrigin === searchOrigin.researcherSearch
              ? researchFields
              : orgFields,
            [...nonEditableAlertSearchExcludedFilters]
          )
        : [];

      const missingIndustryLicense =
        !hasOrgLicense && r.searchOrigin === searchOrigin.organisationSearch;

      return (
        <Fragment key={`${r.id}-${i}`}>
          <RecentResults
            onClick={last30DaysClicked}
            ref={ref}
            alert={r}
            parsedSearch={parsedSearch}
            disabled={missingIndustryLicense}
          />
          {r.name?.length >= 21 ? (
            <TablePopover
              content={
                <div
                  style={{ marginLeft: '-16px', fontSize: 16, fontWeight: 500 }}
                >
                  {r.name}
                </div>
              }
              parentRef={ref}
              widthRatio={0.7}
              color='white'
            >
              <div style={{ fontSize: 16, fontWeight: 500 }}>
                {r.name.substring(0, 18)}..
              </div>
            </TablePopover>
          ) : (
            <div style={{ fontSize: 16, fontWeight: 500 }}>{r.name ?? ''}</div>
          )}
          <div>
            {
              <TablePopover
                content={
                  <div stlye={{ paddingTop: '-50px' }}>
                    {parsedSearch ? parsedSearch['term'] : r.monthlyKeywords}
                  </div>
                }
                parentRef={ref}
                widthRatio={0.7}
                color='#d9d9d9'
              >
                <BorderDiv color='#d9d9d9'>
                  <Icon size='sm' icon='code' color='#595959' />
                </BorderDiv>
              </TablePopover>
            }
          </div>
          <div>
            {parsedSearch ? (
              <TablePopover
                color='#c8b3fb'
                content={
                  <HoverDiv style={{ flexDirection: 'column' }}>
                    {pluralise('Filter', tokens)} applied:
                    <ListTokens
                      filter={parsedSearch}
                      inputView={
                        r.searchOrigin === searchOrigin.researcherSearch
                          ? view.researcher
                          : view.organisation
                      }
                    />
                  </HoverDiv>
                }
                parentRef={ref}
              >
                <HoverDiv
                  style={{
                    border: '2px solid #c8b3fb',
                    borderRadius: '25px',
                    height: '25px',
                    minWidth: '120px',
                  }}
                >
                  {tokens.length} {pluralise('filter', tokens)} applied
                </HoverDiv>
              </TablePopover>
            ) : (
              <TablePopover
                content={
                  <div>Legacy saved searches cannot show filters or lists</div>
                }
                parentRef={ref}
                widthRatio={0.7}
                color='#d9d9d9'
              >
                <BorderDiv
                  color='#d9d9d9'
                  style={{ width: '30px', height: '30px' }}
                >
                  <Icon size='sm' icon='clockRotateLeft' color='#595959' />
                </BorderDiv>
              </TablePopover>
            )}
          </div>
          <div>
            {r.structuredSearch &&
            (r.lists?.length || r.organisationLists?.length) ? (
              <TablePopover
                color='#b5ddde'
                content={
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                    }}
                  >
                    {getListPopover(
                      r.lists,
                      r.organisationLists,
                      'Included',
                      '#b5ddde'
                    )}
                  </div>
                }
                parentRef={ref}
              >
                <HoverDiv
                  style={{
                    textAlign: 'center',
                    border: '2px solid #b5ddde',
                    borderRadius: '25px',
                    height: '25px',
                    minWidth: '120px',
                  }}
                >
                  {getListCount(r.lists, r.organisationLists)} included{' '}
                  {pluralise('list', [r.lists, r.organisationLists])}
                </HoverDiv>
              </TablePopover>
            ) : (
              <div></div>
            )}
          </div>
          <div>
            {r.structuredSearch &&
            (r.excludeLists?.length || r.organisationExcludeLists?.length) ? (
              <TablePopover
                color='#f59fa4'
                content={
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                    }}
                  >
                    {getListPopover(
                      r.excludeLists,
                      r.organisationExcludeLists,
                      'Excluded',
                      '#f59fa4'
                    )}
                  </div>
                }
                parentRef={ref}
              >
                <HoverDiv
                  style={{
                    border: '2px solid #f59fa4',
                    borderRadius: '25px',
                    height: '25px',
                    minWidth: '120px',
                  }}
                >
                  {getListCount(r.excludeLists, r.organisationExcludeLists)}{' '}
                  excluded{' '}
                  {pluralise('list', [
                    r.excludeLists,
                    r.organisationExcludeLists,
                  ])}
                </HoverDiv>
              </TablePopover>
            ) : (
              <div></div>
            )}
          </div>
          <div>
            {r.structuredSearch && r.searchOrigin ? (
              <TablePopover
                content={
                  <div>
                    This search was created from the{' '}
                    {r.searchOrigin === searchOrigin.researcherSearch
                      ? 'Researcher'
                      : 'Organisation'}{' '}
                    search.
                  </div>
                }
                parentRef={ref}
                widthRatio={0.7}
                color='#d9d9d9'
              >
                <BorderDiv color='#d9d9d9'>
                  <Icon
                    size='sm'
                    icon={
                      r.searchOrigin === searchOrigin.researcherSearch
                        ? 'user'
                        : 'company'
                    }
                    color='gray'
                  />
                </BorderDiv>
              </TablePopover>
            ) : (
              <div></div>
            )}
          </div>
          <div>{frequency ? frequency.value : r.frequency}</div>
          <TableActions className='rightAlign'>
            <Tooltip title='Delete Saved Search'>
              <Button
                size='small'
                data-testid='delete-alert'
                onClick={() => dispatch(setAlertToDelete(r))}
              >
                <Icon icon='trash' size='sm' color='#353535' />
              </Button>
            </Tooltip>
            <Tooltip title='Edit Saved Search'>
              <Button
                size='small'
                data-testid='edit-alert'
                disabled={
                  (!r.structuredSearch && r.nestedQueries) ||
                  missingIndustryLicense
                }
                title={
                  !r.structuredSearch && r.nestedQueries
                    ? 'Cannot edit venture capital queries'
                    : ''
                }
                onClick={() =>
                  dispatch(
                    setAlertToEdit({
                      ...r,
                      frequency: frequency ? frequency.value : r.frequency,
                    })
                  )
                }
              >
                <Icon icon='edit' size='sm' color='#353535' />
              </Button>
            </Tooltip>
            <div style={{ position: 'relative' }}>
              <Button
                type='primary'
                size='small'
                data-testid='search-alert'
                disabled={
                  missingIndustryLicense ||
                  (!r.structuredSearch && r.nestedQueries)
                }
                title={
                  !r.structuredSearch && r.nestedQueries
                    ? 'Cannot re-search venture capital queries'
                    : ''
                }
                onClick={() => handleSearch(r)}
              >
                <Icon icon='faSearch' size='sm' color='white' />
                Search
              </Button>

              {!missingIndustryLicense &&
                !r.structuredSearch &&
                r.nestedQueries && (
                  <Tooltip
                    title='Saved searches using Venture Capital filters cannot be edited or re-searched'
                    placement='right'
                  >
                    <InfoItem>
                      <Icon icon='circleInfo' color='red' size='lg' />
                    </InfoItem>
                  </Tooltip>
                )}
              {missingIndustryLicense && (
                <Tooltip
                  title='You no longer have the necessary license to perform this search'
                  placement='right'
                >
                  <InfoItem>
                    <Icon icon='circleInfo' color='#1890ff' size='lg' />
                  </InfoItem>
                </Tooltip>
              )}
            </div>
          </TableActions>
        </Fragment>
      );
    },
  };

  const pluralise = (term, arr) => {
    if (arr && arr.filter(x => x !== undefined).length > 1) {
      term += 's';
    }
    return term;
  };

  const getListCount = (list, orgList) => {
    let listCount = list?.length > 0 ? list.length : 0;
    let orgListCount = orgList?.length > 0 ? orgList?.length : 0;
    return listCount + orgListCount;
  };

  const getListPopover = (list, orgList, type, color) => {
    return (
      <div>
        {list?.length > 0 ? (
          <div>
            <HoverDiv style={{ flexDirection: 'column' }}>
              {type} researcher {pluralise('list', list)}:
            </HoverDiv>
            {list?.map(x => (
              <div style={{ fontSize: '12px' }}>{x.name}</div>
            ))}
          </div>
        ) : (
          <div></div>
        )}
        {list?.length > 0 && orgList?.length > 0 ? (
          <hr
            style={{
              border: '1px solid',
              paddingRight: '0px',
              paddingLeft: '30px',
              zIndex: '1000',
              borderColor: color,
              width: 'auto',
            }}
          />
        ) : (
          <div></div>
        )}
        {orgList?.length > 0 ? (
          <div>
            <HoverDiv style={{ flexDirection: 'column' }}>
              {type} organisation {pluralise('list', orgList)}:
            </HoverDiv>
            {orgList?.map(x => (
              <div style={{ fontSize: '12px' }}>{x.name}</div>
            ))}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  const last30DaysClicked = alert => {
    if (alert.structuredSearch) {
      dispatch(
        setFiltersFromAlert(
          alert.searchOrigin,
          JSON.parse(alert.structuredSearch),
          'one'
        )
      );
    } else {
      dispatch(
        setExternalSearchFromSavedSearch(
          alert.searchOrigin,
          alert.monthlyKeywords,
          'one'
        )
      );
    }
    push('/');
  };

  const handleSearch = alert => {
    if (alert.structuredSearch) {
      dispatch(
        setFiltersFromAlert(
          alert.searchOrigin,
          JSON.parse(alert.structuredSearch)
        )
      );
    } else {
      let search = {
        term: alert.monthlyKeywords,
        lists: alert.lists,
        excludeLists: alert.excludeLists,
      };
      if (!alert.lists || alert.lists.length === 0) {
        delete search.lists;
      }
      if (!alert.excludeLists || alert.excludeLists.length === 0) {
        delete search.excludeLists;
      }
      dispatch(setExternalSearch(search));
      dispatch(
        setView(
          alert.searchOrigin &&
            search.searchOrigin === searchOrigin.organisationSearch
            ? views.organisations
            : views.researchers
        )
      );
    }
    push('/');
  };

  const onUpdateSuccess = () => {
    if (editAlert) {
      const data = [...alerts];
      const alertIndex = data.findIndex(a => a.id === editAlert.id);
      if (alertIndex >= 0) {
        data[alertIndex] = editAlert;
        setData(data);
      }
      dispatch(setAlertToEdit());
    }
  };

  const onDeleteSuccess = () => {
    if (deletedAlert) {
      const data = [...alerts];
      const alertIndex = data.findIndex(a => a.id === deletedAlert.id);
      if (alertIndex >= 0) {
        setData({ query: { UserId: user.userId, shouldLog: false } });
      }
      dispatch(setAlertToDelete());
    }
  };

  const handleSort = (a, b, sort, sortDir) => {
    let aValue = a[sort];
    let bValue = b[sort];

    if (!aValue && bValue) return 1;
    if (aValue && !bValue) return -1;

    if (['lists', 'excludeLists'].includes(sort)) {
      aValue = a[sort]?.map(x => x.name.toLowerCase()).join('');
      bValue = b[sort]?.map(x => x.name.toLowerCase()).join('');
    } else if (typeof aValue === 'string' && typeof bValue === 'string') {
      aValue = a[sort]?.toLowerCase() ? a[sort].toLowerCase() : a[sort];
      bValue = b[sort]?.toLowerCase() ? b[sort].toLowerCase() : b[sort];
    }

    if (sortDir === 'asc') {
      return aValue > bValue ? 1 : -1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  };

  useEffect(() => {
    setAlerts(data);
  }, [data]);

  const handleAlertsChange = value => {
    dispatch(setAlertFilter(value));
  };

  const getFilteredData = () => {
    if (!alerts) return [];
    if (!alertsFilter) return data;

    return alerts.filter(
      k =>
        k.name && `${k.name.toLowerCase()}`.includes(alertsFilter.toLowerCase())
    );
  };

  return (
    <div ref={ref}>
      <Input
        placeholder='Filter Saved Search'
        style={{ marginRight: 15, marginTop: 15 }}
        onChange={handleAlertsChange}
        value={alertsFilter}
        allowClear
      />
      <StyledTable
        config={config}
        data={getFilteredData()}
        pageSizeOptions={tablePerPage}
        onSort={handleSort}
        sortable
        paginate
        loading={loading}
      />
      {editAlert && <EditModal onUpdateSuccess={onUpdateSuccess} />}
      {deletedAlert && <DeleteModal onDeleteSuccess={onDeleteSuccess} />}
    </div>
  );
};
export default SavedAlerts;
