import styled from 'styled-components';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { setShowBuilder } from 'actions/search';
import { Form, Button, Icon } from 'components/common';
import { DisabledButtonWrapper } from 'components/common/styled.js';
import { Tooltip } from 'antd';
import { ActionButtons, SearchAreaContainer } from './styled';
import { getShowBuilder } from 'selectors/search';
import SearchBuilder from '../../Search/SearchArea/SearchBuilder';
import OrgFilterField from '../Filters/OrgFilterField';
import {
  clearFilters,
  setFilterGroup,
  setWorkItemCategoryFilterGroup,
} from 'actions/organisation';
import {
  getFilter,
  getWorkItemCategoryFilter,
  getHasValidQuery,
  SLICE,
  getOrganisationResearchersLoading,
} from 'selectors/organisation';

import { useAuth } from 'hooks';

import licenseType from 'models/licenseType';
import { performBoolSearch, performPhraseSearch } from 'selectors/users';
import BoolErrorMessage from 'components/Search/BoolErrorMessage';
import { SearchDiv } from 'components/Item/styled';

export const SearchBar = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
`;

const CategoryCheckboxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
`;

const OrganisationResearchersSearchArea = ({
  organisation,
  handleSearch,
  loading,
}) => {
  const dispatch = useDispatch();
  const showBuilder = useSelector(getShowBuilder);

  const [showBoolError, setShowBoolError] = useState(false);
  const getHasBoolError = useSelector(
    performBoolSearch(SLICE, organisation.id)
  );
  const getHasPhraseError = useSelector(
    performPhraseSearch(SLICE, organisation.id)
  );
  const isDataloading = useSelector(
    getOrganisationResearchersLoading(organisation.id)
  );
  const isLoading = isDataloading || loading;
  const searchTermRef = useRef();

  const handleClear = () => {
    dispatch(clearFilters(organisation.id));
    handleSearch();
  };

  const handleSearchBuilderClear = () => {
    dispatch(setFilterGroup(organisation.id, 'term', ''));
  };

  const handleSearchBuilderSearch = value => {
    dispatch(setFilterGroup(organisation.id, 'term', value));
    handleSearch();
  };

  const updateSearch = value => {
    dispatch(setFilterGroup(organisation.id, 'term', value));
  };

  const handleShowBuilder = () => {
    dispatch(setShowBuilder(true));
  };

  const handleLocalSearch = () => {
    if (showBoolError !== null && (getHasBoolError || getHasPhraseError))
      setShowBoolError(true);
    else {
      handleSearch();
    }
  };

  const { hasLicense } = useAuth();
  const hasIndustryLicense = hasLicense([licenseType.industry], true);

  const hasValidQuery = useSelector(getHasValidQuery(organisation.id));
  const searchButton = (
    <Button
      type='primary'
      onClick={handleLocalSearch}
      data-testid='search-button'
      disabled={!hasValidQuery || isLoading}
    >
      Search
    </Button>
  );

  return (
    organisation && (
      <SearchAreaContainer>
        <SearchBar>
          <Button
            style={{ marginRight: 10 }}
            type='secondary'
            onClick={handleShowBuilder}
            disabled={isLoading}
          >
            <Icon icon='magnifyingGlassPlus' color='#8D8D8D' size='sm' />
            <span style={{ marginLeft: '5px' }}>Open Search Builder</span>
          </Button>
          <SearchDiv ref={searchTermRef}>
            <Form width='100%' onSubmit={handleLocalSearch} style={{ flex: 1 }}>
              <OrgFilterField
                placeholder='(NGS OR "Next generation sequencing") AND CRISPR'
                filterId='term'
                organisationId={organisation.id}
                filterType='input'
                data-testid='org-filter-term'
                size='default'
                setFilterGroup={setFilterGroup}
                getFilter={getFilter}
                disabled={isLoading}
              />
            </Form>
            {showBoolError ? (
              <BoolErrorMessage
                updateSearchQuery={value => updateSearch(value)}
                performSearch={handleSearch}
                hasBoolFailed={getHasBoolError}
                term={getFilter(organisation.id, 'term')}
                hasParseFailed={getHasPhraseError}
                searchTermRef={searchTermRef}
                setShowBoolError={setShowBoolError}
              />
            ) : (
              <></>
            )}
          </SearchDiv>
          <ActionButtons>
            <Button
              type='secondary'
              onClick={handleClear}
              data-testid='clear-search-button'
              disabled={isLoading}
            >
              Clear
            </Button>
            {hasValidQuery ? (
              searchButton
            ) : (
              <Tooltip title='Enter search criteria'>
                <DisabledButtonWrapper>{searchButton}</DisabledButtonWrapper>
              </Tooltip>
            )}
          </ActionButtons>
        </SearchBar>
        <CategoryCheckboxes>
          <span style={{ marginRight: '10px', lineHeight: '30px' }}>
            Search across:
          </span>
          <OrgFilterField
            helpText='Funding'
            icon='award'
            filterType='category'
            filterId='funding'
            organisationId={organisation.id}
            setFilterGroup={setWorkItemCategoryFilterGroup}
            getFilter={getWorkItemCategoryFilter}
            disabled={isLoading}
          >
            Funding
          </OrgFilterField>
          <OrgFilterField
            helpText='Tradeshows'
            icon='image'
            filterType='category'
            filterId='tradeshows'
            organisationId={organisation.id}
            setFilterGroup={setWorkItemCategoryFilterGroup}
            getFilter={getWorkItemCategoryFilter}
            disabled={isLoading}
          >
            Tradeshows
          </OrgFilterField>
          <OrgFilterField
            helpText='Publications'
            icon='book'
            filterType='category'
            filterId='publications'
            organisationId={organisation.id}
            setFilterGroup={setWorkItemCategoryFilterGroup}
            getFilter={getWorkItemCategoryFilter}
            disabled={isLoading}
          >
            Publications
          </OrgFilterField>
          <OrgFilterField
            helpText='Clinical Trials'
            icon='trial'
            filterType='category'
            filterId='clinicalTrials'
            organisationId={organisation.id}
            setFilterGroup={setWorkItemCategoryFilterGroup}
            getFilter={getWorkItemCategoryFilter}
            disabled={isLoading}
          >
            Clinical Trials
          </OrgFilterField>
          {hasIndustryLicense && (
            <>
              <OrgFilterField
                helpText='3rd Party Data'
                icon='magnifyingGlass'
                filterType='category'
                filterId='profile'
                organisationId={organisation.id}
                setFilterGroup={setWorkItemCategoryFilterGroup}
                getFilter={getWorkItemCategoryFilter}
                disabled={isLoading}
              >
                3rd Party Data
              </OrgFilterField>
            </>
          )}
        </CategoryCheckboxes>
        {!!showBuilder && (
          <SearchBuilder
            onSearch={handleSearchBuilderSearch}
            showAdvancedFields={false}
            id={organisation.id}
            saveInStore={false}
            onClear={handleSearchBuilderClear}
            filterId='term'
          />
        )}
      </SearchAreaContainer>
    )
  );
};

OrganisationResearchersSearchArea.propTypes = {
  organisation: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  handleSearch: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default OrganisationResearchersSearchArea;
