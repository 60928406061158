import { createContext, useEffect, useRef, useState, useMemo } from 'react';
import { useHistory, useParams, Redirect } from 'react-router-dom';

import { useGet, usePost, useAuth } from 'hooks';
import styled from 'styled-components';

import Profile from './Profile';
import {
  Container,
  Column,
  LeftColumn,
  Pane,
  ContentPane,
  Row,
  ProfileContainer,
} from './styled';
import { TabbedPanel } from 'components/common';
import { Loader } from '../common';
import OrganisationProfileFilters from './Filters';
import OrganisationResearchersSearchArea from './Search';
import { getOrgListsForUser, getSuperOrganisation } from 'services/api';
import OrganisationResearchers from './Researchers';
import OrganisationTradeshows from './Tradeshows';
import OrganisationPurchasing from './Purchasing';
import OrganisationClinicalTrials from './ClinicalTrials';

import Statistics from './Statistics';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearPurchasingFilters,
  performSearch,
  performPurchasingSearch,
  setOrgResearchersSearchRequest,
  setPurchasingSearchRequest,
  setView,
  setPurchasingFilter,
  setOrgWorkItemsSearchRequest,
  performWorkItemSearch,
  setTradeshowsSearchRequest,
  performTradeshowSearch,
  resetAllFilters,
  performClinicalTrialsSearch,
  setClinicalTrialsSearchRequest,
} from 'actions/organisation';
import {
  getOrganisationView,
  getPurchasingSearchRequest,
  getPurchasingLoading,
} from 'selectors/organisation';
import OrganisationItems from './Items';
import { useCallback } from 'react';
import OrganisationWorkItemSearchArea from './Search/ItemSearch';
import useLog, { outcomes } from 'hooks/useLog';
import OrganisationFundingRounds from './Funding';
import CrunchbaseAttribution from './Funding/attribution';
import OrganisationAcquisitions from './Acquisitions';
import {
  ORGANISATIONACQUISITIONSVIEW,
  ORGANISATIONPEOPLEVIEW,
  ORGANISATIONPURCHASINGVIEW,
  ORGANISATIONTRADESHOWSVIEW,
  ORGANISATIONVCFUNDINGVIEW,
  ORGANISATIONWORKITEMVIEW,
  ORGANISATIONINVESTMENTSVIEW,
  ORGANISATIONCLINICALTRIALSVIEW,
} from './helpers';
import OrganisationDownloadButton from './Downloads';
import Lists from 'components/Researcher/Lists';
import { views } from 'components/Lists/constants';
import { setModal } from 'actions/lists';
import { Create } from 'components/common/Lists/Modals';
import { getActiveModal } from 'selectors/lists';
import { TITLE_POPUP_CREATE_LIST } from 'components/common/labelConstants';
import useAddMembersAndRefetch from 'components/Lists/hooks/useAddMembersAndRefetch';
import OrganisationInvestments from './Investments';
import DefunctNotice from './DefunctNotice';
import CTA from './CTA';
import OrganisationClinicalTrialSearchArea from './Search/ClinicalTrialSearch';
import licenseType from 'models/licenseType';

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 7px;
  ${p => p.theme.breakpoint.tablet} {
    padding: 0px;
  }
`;

const metricActions = {
  [ORGANISATIONPEOPLEVIEW]: 'VisitPeopleTab',
  [ORGANISATIONWORKITEMVIEW]: 'VisitActivityTab',
  [ORGANISATIONTRADESHOWSVIEW]: 'VisitTradeshowsTab',
  [ORGANISATIONPURCHASINGVIEW]: 'VisitPurchasingTab',
  [ORGANISATIONVCFUNDINGVIEW]: 'VisitVcFundingTab',
  [ORGANISATIONACQUISITIONSVIEW]: 'VisitAcquisitionsTab',
  [ORGANISATIONINVESTMENTSVIEW]: 'VisitInvestmentsTab',
  [ORGANISATIONCLINICALTRIALSVIEW]: 'VisitClinicalTrialsTab',
};

const modals = {
  create: Create,
};

export const OrgSearchContext = createContext();
export const OrgPurchasingSearchContext = createContext();

const Organisation = () => {
  const { id } = useParams();
  const idRef = useRef(id);
  const parentRef = useRef(null);
  const dispatch = useDispatch();
  const activeTab = useSelector(getOrganisationView);
  const purchasingSearchRequest = useSelector(getPurchasingSearchRequest(id));
  const purchasingLoading = useSelector(getPurchasingLoading);
  const activeModal = useSelector(getActiveModal);
  const { hasLicense } = useAuth();

  const hasIndustryLicense = hasLicense([licenseType.industry], true);

  const [{ res: superOrg, loading }, fetchSuperOrg] = useGet({
    url: getSuperOrganisation(id),
  });

  const [
    { res: editableListsResponse, loading: loadingLists },
    fetchLists,
  ] = useGet({
    url: getOrgListsForUser,
    onMount: true,
    query: { onlyEditable: true },
  });

  const [, handleAddMembers, list] = useAddMembersAndRefetch(
    null,
    fetchLists,
    views.organisations,
    true
  );

  const listOptions = useMemo(
    () =>
      editableListsResponse?.map(l => ({
        label: l.name,
        value: l.id,
        description: l.description,
      })),
    [editableListsResponse]
  );

  const [searchState, postSearch] = usePost({});
  const [tradeshowSearchState, postTradeshowSearch] = usePost({});
  const [purchasingSearchState, postPurchasingSearch] = usePost({});
  const [workItemSearchState, postWorkItemSearchState] = usePost({});
  const [clinicalTrialsSearchState, postClinicalTrialsSearch] = usePost({});

  const searchParams = new URLSearchParams(
    new URL(window.location.href)?.search
  );
  const initialView = searchParams?.get('initialView');
  const linkNavigation = useRef(null);
  const history = useHistory();

  const [getIsReset, setIsReset] = useState(false);
  const [peopleInitialLoad, setPeopleInitialLoad] = useState(true);
  const [workItemsInitialLoad, setWorkItemsInitialLoad] = useState(true);
  const [tradeshowsInitialLoad, setTradeshowsInitialLoad] = useState(true);
  const [purchasingInitialLoad, setPurchasingInitialLoad] = useState(true);
  const [clinicalTrialsInitialLoad, setClinicalTrialsInitialLoad] = useState(
    true
  );

  useEffect(() => {
    dispatch(resetAllFilters(id, hasIndustryLicense));
    setIsReset(true);
    fetchSuperOrg();
    setPeopleInitialLoad(true);
    setWorkItemsInitialLoad(true);
    setTradeshowsInitialLoad(true);
    setPurchasingInitialLoad(true);
  }, [id, fetchSuperOrg, hasIndustryLicense, dispatch]);

  useEffect(() => {
    if (superOrg && !superOrg.redirectId) {
      document.title = `${superOrg.organisationName} | SciLeads`;

      if (Object.keys(config).includes(initialView)) {
        linkNavigation.current = true;
        dispatch(setView(initialView));
        history.replace(history.location.pathname);
      } else if (!Object.keys(config).includes(activeTab)) {
        dispatch(setView(ORGANISATIONPEOPLEVIEW));
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [superOrg]);

  useEffect(() => {
    idRef.current = id;
  }, [id]);

  useEffect(() => {
    if (searchState.res || searchState.error || searchState.loading) {
      dispatch(setOrgResearchersSearchRequest(idRef.current, searchState));
    }
  }, [searchState, dispatch]);

  useEffect(() => {
    if (
      purchasingSearchState.res ||
      purchasingSearchState.error ||
      purchasingSearchState.loading
    ) {
      dispatch(
        setPurchasingSearchRequest(idRef.current, purchasingSearchState)
      );
    }
  }, [purchasingSearchState, dispatch]);

  useEffect(() => {
    if (
      workItemSearchState.res ||
      workItemSearchState.error ||
      workItemSearchState.loading
    ) {
      dispatch(
        setOrgWorkItemsSearchRequest(idRef.current, workItemSearchState)
      );
    }
  }, [workItemSearchState, dispatch]);

  useEffect(() => {
    if (
      tradeshowSearchState.res ||
      tradeshowSearchState.error ||
      tradeshowSearchState.loading
    ) {
      dispatch(setTradeshowsSearchRequest(idRef.current, tradeshowSearchState));
    }
  }, [tradeshowSearchState, dispatch]);

  useEffect(() => {
    if (
      clinicalTrialsSearchState.res ||
      clinicalTrialsSearchState.error ||
      clinicalTrialsSearchState.loading
    ) {
      dispatch(setClinicalTrialsSearchRequest(id, clinicalTrialsSearchState));
    }
  }, [clinicalTrialsSearchState, dispatch, id]);

  const showLoader = loading || !superOrg;

  const handleSearch = () => {
    dispatch(performSearch(postSearch, id));
  };

  const handleTabChange = value => {
    dispatch(setView(value));
  };

  const handleWorkItemSearch = useCallback(() => {
    dispatch(performWorkItemSearch(postWorkItemSearchState, id));
  }, [dispatch, postWorkItemSearchState, id]);

  const handleCreateNewList = () => {
    setTimeout(() => dispatch(setModal('create')), 400);
  };

  const handleCreateListModalClose = options => {
    if (options?.id && activeModal === 'create') {
      handleAddMembers(options, id);
    }

    dispatch(setModal(''));
  };

  const handleClinicalTrialSearch = useCallback(() => {
    dispatch(
      performClinicalTrialsSearch(postClinicalTrialsSearch, {
        organisationId: id,
        organisationName: superOrg.organisationName,
      })
    );
  }, [dispatch, postClinicalTrialsSearch, id, superOrg]);

  //initial load
  useEffect(() => {
    if (!superOrg || initialView || !peopleInitialLoad) return;

    if (activeTab === ORGANISATIONPEOPLEVIEW) {
      dispatch(performSearch(postSearch, id));
      setPeopleInitialLoad(false);
    }
  }, [
    dispatch,
    superOrg,
    id,
    postSearch,
    initialView,
    peopleInitialLoad,
    activeTab,
  ]);

  useEffect(() => {
    if (!superOrg || initialView || !workItemsInitialLoad) return;

    if (activeTab === ORGANISATIONWORKITEMVIEW) {
      handleWorkItemSearch();
      setWorkItemsInitialLoad(false);
    }
  }, [
    dispatch,
    superOrg,
    handleWorkItemSearch,
    initialView,
    activeTab,
    workItemsInitialLoad,
  ]);

  useEffect(() => {
    if (!superOrg || initialView || !tradeshowsInitialLoad) return;

    if (activeTab === ORGANISATIONTRADESHOWSVIEW) {
      dispatch(performTradeshowSearch(postTradeshowSearch, id));
      setTradeshowsInitialLoad(false);
    }
  }, [
    activeTab,
    dispatch,
    id,
    initialView,
    postTradeshowSearch,
    superOrg,
    tradeshowsInitialLoad,
  ]);

  useEffect(() => {
    if (!superOrg || initialView || !clinicalTrialsInitialLoad) return;

    if (activeTab === ORGANISATIONCLINICALTRIALSVIEW) {
      dispatch(
        performClinicalTrialsSearch(postClinicalTrialsSearch, {
          organisationId: id,
          organisationName: superOrg.organisationName,
          newSearch: true,
        })
      );
      setClinicalTrialsInitialLoad(false);
    }
  }, [
    activeTab,
    dispatch,
    id,
    initialView,
    postClinicalTrialsSearch,
    superOrg,
    clinicalTrialsInitialLoad,
  ]);

  useEffect(() => {
    if (!superOrg || initialView || !purchasingInitialLoad) return;

    if (activeTab === ORGANISATIONPURCHASINGVIEW) {
      dispatch(clearPurchasingFilters);
      dispatch(
        setPurchasingFilter('supplierName', [superOrg.organisationName])
      );
      dispatch(
        performPurchasingSearch(postPurchasingSearch, {
          organisationId: id,
          newSearch: true,
        })
      );
      setPurchasingInitialLoad(false);
    }
  }, [
    activeTab,
    dispatch,
    id,
    initialView,
    postPurchasingSearch,
    purchasingInitialLoad,
    purchasingLoading,
    purchasingSearchRequest,
    superOrg,
  ]);

  const postLog = useLog(null, null, null, false);
  useEffect(() => {
    if (activeTab && !initialView) {
      postLog({
        description: JSON.stringify({
          entityId: id,
          location: linkNavigation.current ? 'Link' : 'Navigation',
        }),
        action: metricActions[activeTab],
        outcome: outcomes.success,
      });
      linkNavigation.current = false;
    }
  }, [activeTab, id, initialView, postLog]);

  if (superOrg?.redirectId) {
    return <Redirect to={`/organisation/${superOrg.redirectId}`} />;
  }

  const config = {};

  config[ORGANISATIONPEOPLEVIEW] = {
    icon: 'users',
    text: 'People',
    content: (
      <TableContainer>
        <OrganisationResearchersSearchArea
          organisation={superOrg}
          handleSearch={handleSearch}
          loading={peopleInitialLoad}
        />
        <OrganisationResearchers
          organisation={superOrg}
          handleSearch={handleSearch}
          loading={peopleInitialLoad}
        />
      </TableContainer>
    ),
  };
  config.workItems = {
    icon: 'folderOpen',
    text: 'Activity',
    content: (
      <TableContainer>
        <OrganisationWorkItemSearchArea
          organisation={superOrg}
          handleSearch={handleWorkItemSearch}
          isReset={getIsReset}
          setReset={setIsReset}
          loading={workItemsInitialLoad}
        />
        <OrganisationItems
          organisation={superOrg}
          handleSearch={handleWorkItemSearch}
          loading={workItemsInitialLoad}
          parentRef={parentRef}
        />
      </TableContainer>
    ),
  };
  config[ORGANISATIONTRADESHOWSVIEW] = {
    icon: 'calendar',
    text: 'Tradeshows',
    content: (
      <OrganisationTradeshows
        organisation={superOrg}
        loading={tradeshowsInitialLoad}
      />
    ),
  };

  if (superOrg?.sponsoredTrials > 0 || superOrg?.totalClinicalTrials > 0) {
    config[ORGANISATIONCLINICALTRIALSVIEW] = {
      icon: 'trial',
      text: 'Clinical Trials',
      content: (
        <TableContainer>
          <OrganisationClinicalTrialSearchArea
            organisation={superOrg}
            handleSearch={handleClinicalTrialSearch}
            loading={clinicalTrialsInitialLoad}
          />
          <OrganisationClinicalTrials
            organisation={superOrg}
            loading={clinicalTrialsInitialLoad}
            handleSearch={handleClinicalTrialSearch}
          />
        </TableContainer>
      ),
    };
  }

  config[ORGANISATIONPURCHASINGVIEW] = {
    icon: 'searchDollar',
    text: 'Products Sold',
    content: (
      <OrganisationPurchasing
        organisation={superOrg}
        loading={purchasingInitialLoad}
      />
    ),
  };

  if (superOrg?.superOrganisationFundingRounds?.length) {
    config[ORGANISATIONVCFUNDINGVIEW] = {
      icon: 'sackDollar',
      text: 'VC Funding',
      content: <OrganisationFundingRounds organisation={superOrg} />,
    };
  }

  if (superOrg?.superOrganisationAcquisitions?.length) {
    config[ORGANISATIONACQUISITIONSVIEW] = {
      icon: 'handShake',
      text: 'Acquisitions',
      content: <OrganisationAcquisitions organisation={superOrg} />,
    };
  }

  if (superOrg?.superOrganisationInvestments?.length) {
    config[ORGANISATIONINVESTMENTSVIEW] = {
      icon: 'dollarNote',
      text: 'Investments',
      content: <OrganisationInvestments organisation={superOrg} />,
    };
  }

  if (showLoader) {
    return <Loader />;
  }

  const ModalCreateNewList = modals[activeModal];
  return superOrg.showCta ? (
    <CTA></CTA>
  ) : (
    <>
      <OrgSearchContext.Provider value={postSearch}>
        <OrgPurchasingSearchContext.Provider value={postPurchasingSearch}>
          <Container>
            <Row alignTop>
              <LeftColumn>
                <Pane>
                  <ProfileContainer>
                    <Profile loading={showLoader} organisation={superOrg} />
                  </ProfileContainer>
                </Pane>
                <OrganisationProfileFilters
                  organisation={superOrg}
                  onFilterUpdate={handleSearch}
                  onworkItemFilterUpdate={handleWorkItemSearch}
                />
                <Pane title='List Management' style={{ marginTop: 15 }}>
                  <Lists
                    editableLists={listOptions}
                    loadingLists={loadingLists}
                    type={views.organisations}
                    onCreateNewItemList={handleCreateNewList}
                    newListCreatedId={list?.id}
                    organisationName={superOrg.organisationName}
                  />
                </Pane>
              </LeftColumn>
              <Column ref={parentRef}>
                <Statistics organisation={superOrg} loading={showLoader} />
                <DefunctNotice organisation={superOrg} />
                <ContentPane>
                  <TabbedPanel
                    horizontal
                    activeTab={activeTab}
                    onActiveTabChange={handleTabChange}
                    config={config}
                    leftMargin
                  >
                    <OrganisationDownloadButton organisation={superOrg} />
                  </TabbedPanel>
                </ContentPane>
                <CrunchbaseAttribution activeTab={activeTab} />
              </Column>
            </Row>
            {activeModal && (
              <ModalCreateNewList
                title={TITLE_POPUP_CREATE_LIST}
                buttonOkText='Create and Add'
                onClose={handleCreateListModalClose}
                type={views.organisations}
              />
            )}
          </Container>
        </OrgPurchasingSearchContext.Provider>
      </OrgSearchContext.Provider>
    </>
  );
};

export const getLinkedInNetwork = () => {
  return {
    type: 'linkedin',
    displayName: 'LinkedIn',
  };
};

export default Organisation;
