import styled from 'styled-components';
import { Pane as BasePane } from 'components/common';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  img {
    margin-bottom: 10px;
  }
`;

export const InnerContent = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 10px;
    :last-child {
      margin-bottom: 0px;
    }
  }
  p {
    text-align: center;
    font-size: 14px;
    line-height: 1.3em;
  }
  .enrollment-required {
    text-align: center;
    font-weight: 500;
  }
`;

export const Pane = styled(BasePane)`
  width: auto;
  flex: 0;
  display: inline-flex;
  .content {
    flex-direction: row;
    ${p => p.theme.breakpoint.mobile} {
      flex-direction: column;
    }
  }
`;

export const FormContent = styled.div`
  width: 250px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  padding-left: 15px;
  > span {
    font-weight: 500;
    text-align: center;
    padding-bottom: 7px;
  }
  > input {
    margin-bottom: 10px;
  }
  ${p => p.theme.breakpoint.mobile} {
    flex-direction: column;
    margin-left: 0px;
    padding-left: 0px;
    margin-top: 15px;
    padding-top: 15px;
    border-left: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

export const TfaMessageContainer = styled.div`
  p {
    text-align: left;
  }
  .more-container {
    text-align: right;
  }
`;
