import { Fragment, useEffect, useState, useRef } from 'react';
import { Col, Row, Tooltip } from 'antd';

import { Link } from 'components/common';
import { useSelector, useDispatch } from 'react-redux';
import { Detail } from './styled';
import { Select } from 'components/common';
import { LABEL_BUTTON_ADD_NEW_LIST } from 'components/common/labelConstants';
import { getListsForUser, manageMembers, deleteList } from 'services/api';
import { useGet, usePatch, useDel } from 'hooks';
import {
  NotificationFailure,
  NotificationSuccess,
} from 'components/Lists/Notifications';
import { setModal } from 'actions/lists';
import { Create } from 'components/common/Lists/Modals';
import { TITLE_POPUP_CREATE_LIST } from 'components/common/labelConstants';
import { getActiveModal } from 'selectors/lists';
import styled from 'styled-components';
import listRenderOption from 'components/common/Lists/ListRenderOptions';

const modals = {
  create: Create,
};

export const AuthorDiv = styled.div`
  line-height: 1.4em;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  color: rgb(140, 140, 140);
`;

const Author = ({
  initials,
  lastName,
  collectiveName,
  scileadsSuperResearcherId,
  orgListIds,
  orgs,
}) => {
  let content =
    initials || lastName ? (
      <span>
        {initials} {lastName}
      </span>
    ) : (
      <span>{collectiveName}</span>
    );
  if (scileadsSuperResearcherId) {
    content = (
      <Link inline to={`/researcher/${scileadsSuperResearcherId}`}>
        {initials} {lastName}
      </Link>
    );
  }
  if (!orgListIds || !orgs || orgListIds.length === 0) return content;

  const title = orgListIds
    .split(',')
    .map(id => <p key={id}>{orgs[+id].organisation.organisation}</p>);
  return <Tooltip title={title}>{content}</Tooltip>;
};

const Authors = ({ data }) => {
  const dispatch = useDispatch();
  const activeModal = useSelector(getActiveModal);
  const [isNewList, setIsNewList] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const isNewListRef = useRef();
  const listIdRef = useRef();
  const { pubmedAuthors, pubmedAffiliations } = data;

  const sensibleOrgs = Object.values(pubmedAffiliations).reduce((acc, org) => {
    return {
      ...acc,
      [org.indexCount]: org,
    };
  }, {});

  const [
    { res: editableListsResponse, loading: loadingLists },
    fetchList,
  ] = useGet({
    url: getListsForUser,
    onMount: true,
    query: { onlyEditable: true },
  });

  const [
    {
      res: addRes,
      loading: addingResearchers,
      error: addingMembersToListError,
    },
    patchMembers,
  ] = usePatch({});

  const [{ res }, delList] = useDel({
    url: '',
  });

  const listOptions =
    editableListsResponse && editableListsResponse.length > 0
      ? editableListsResponse.map(l => ({
          label: l.name,
          value: l.id,
          description: l.description,
        }))
      : [{ label: 'No lists available', value: 'no_lists' }];

  useEffect(() => {
    isNewListRef.current = isNewList;
  }, [isNewList]);

  useEffect(() => {
    if (addRes) {
      NotificationSuccess();
      fetchList();
    }

    if (addingMembersToListError && addingMembersToListError.status === 400) {
      if (!isNewListRef.current) {
        setErrorMessage(''); // This set is to avoid execute the effect below.
        NotificationFailure(addingMembersToListError.message);
      } else {
        setErrorMessage(addingMembersToListError.message);
        delList({
          url: deleteList(listIdRef.current),
        });
      }
    }
  }, [addRes, addingMembersToListError, fetchList, delList]);

  const handleAddAll = id => {
    if (id === 'no_lists') return;
    setIsNewList(false);
    handleOk(id);
  };

  const handleOk = listId => {
    patchMembers({
      url: manageMembers(listId),
      body: {
        researcherIds: pubmedAuthors
          .filter(x => x.scileadsSuperResearcherId)
          .map(x => x.scileadsSuperResearcherId),
      },
    });
  };

  const handleCreateNewWork = colTable => {
    setIsNewList(true);
    setTimeout(() => dispatch(setModal('create')), 400);
  };

  const ModalCreateNewList = modals[activeModal];

  const handleCloseModal = (options = { fetch: false, id: null }) => {
    dispatch(setModal(''));
    if (options.id && options.id !== null) {
      listIdRef.current = options.id;
      handleOk(options.id);
    }
  };

  useEffect(() => {
    if (res && errorMessage.trim().length > 0) {
      NotificationFailure(errorMessage, true);
    }
  }, [res, errorMessage]);

  return (
    <>
      <Row>
        <Col flex={1}>
          <Row>
            <AuthorDiv>Authors</AuthorDiv>
          </Row>
        </Col>
        <Col flex={5}>
          <Row justify='end'>
            <Select
              value='Add All Authors to List'
              placeholder='Add All Authors to List'
              onCreateNewItem={() => {
                handleCreateNewWork();
              }}
              buttonText={LABEL_BUTTON_ADD_NEW_LIST}
              size='small'
              disabled={loadingLists || addingResearchers}
              options={listOptions}
              onChange={handleAddAll}
              dropdownStyle={{ minWidth: '225px' }}
              renderOption={(o, i) => listRenderOption({ ...o }, i)}
              style={{ marginLeft: 10, width: 190 }}
              filterOption={(input, option) =>
                option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            />
          </Row>
        </Col>
      </Row>
      <Detail>
        {pubmedAuthors.map((a, i) => {
          if (i === pubmedAuthors.length - 1)
            return (
              <Author {...a} key={`${a.initials}-${i}`} orgs={sensibleOrgs} />
            );
          return (
            <Fragment key={`${a.initials}-${i}`}>
              <Author {...a} orgs={sensibleOrgs} />,{' '}
            </Fragment>
          );
        })}
      </Detail>

      {activeModal && (
        <ModalCreateNewList
          title={TITLE_POPUP_CREATE_LIST}
          buttonOkText='Create and Add'
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default Authors;
