import styled from 'styled-components';
import { Button, Typography } from 'antd';

const { Text } = Typography;

const CTAContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-top: 20px;
`;

const CTA = () => {
  return (
    <CTAContainer>
      <Text>Not a customer?</Text>
      <Button target={'_blank'} href={'https://scileads.com/get-started/'}>
        Try for Free
      </Button>
    </CTAContainer>
  );
};

export default CTA;
