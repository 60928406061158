import { useMemo } from 'react';
import { setPdfExportStatus } from 'actions/search';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLastSuccessfulSearch,
  getSearchRequest,
  getSearchView,
  isPdfExportRunning,
} from 'selectors/search';
import React, { useEffect, useCallback } from 'react';
import { format } from 'utils/date';
import HorizontalReportTable from './HorizontalReportTable';
import styled from 'styled-components';
import Funding from '../../Funding';
import Publications from '../../Publications';
import { PdfStatistic } from 'components/common';
import { formatCurrency } from 'utils/number';
import { useAuth } from 'hooks';
import jsPDF from 'jspdf';
import dayjs from 'dayjs';
import { Stats } from './styled';
import {
  OrganisationIcon,
  TradeshowIcon,
  ArticlesIcon,
  ClinicalIcon,
  FundingIcon,
  EmailIcon,
  SciLeadsLogo,
} from 'components/common';
import { font, italicFont, boldFont } from './font';

import { usePost } from 'hooks';
import { log } from 'services/api';
import { ResearcherSearch } from 'components/Search/Results/helpers';

export const Title = styled.div`
  font-size: 26px;
  color: #5a5a5a;
  font-weight: bold;
  margin: 5px 0px 5px 0px;
  span {
    margin-right: 10px;
  }
`;

export const SubTitle = styled.div`
  padding-top: 10px;
  color: #5a5a5a;
  font-size: 22px;
  font-weight: semi-bold;
  text-align: center;
`;

//invisible block for end user
export const ExportContainer = styled.div`
  width: 612pt;
  top: -10000px;
  position: absolute;
  background-color: white;
`;

export const ExportPages = styled.div`
  width: 612pt;
`;

export const PageContainer = styled.div`
  height: 815pt;
  width: 100%;
  padding: 20px;
  font-size: 16px;
  color: '#5a5a5a';
  position: relative;
`;

export const TitlePage = styled.div`
  display: flex;
  flex-direction: column;
  height: 1100px;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  .item {
    margin-top: 5rem;
    max-width: 85%;
  }
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px #ccc solid;
  padding-bottom: 5px;
  padding-top: 10px;
  height: 50px;
`;

export const PageFooter = styled.div`
  display: flex;
  justify-content: space-between;
  font-style: italic;
  border-top: 1px #ccc solid;
  background-color: white;
  padding-top: 5px;
  padding-right: 5px;
  position: absolute;
  bottom: 5px;
  width: 95%;
`;

const SearchTerm = styled.div`
  font-size: 16px;
  font-weight: semi-bold;
  font-style: italic;
  text-align: center;
`;

export const MapContainer = styled.div`
  height: 550px;
`;

const PdfReport = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { res: data } = useSelector(getSearchRequest);
  const isPdfExportStarted = useSelector(isPdfExportRunning);
  const term = useSelector(getLastSuccessfulSearch).query;
  const isResearcher = ResearcherSearch(useSelector(getSearchView));

  const [, logPdfExport] = usePost({
    url: log,
  });

  const pdfDocumentName = `scileads.com`;

  const sleep = ms => new Promise(r => setTimeout(r, ms));

  const exportPdf = useCallback(async () => {
    logPdfExport({
      description: `{"term":"${term}"}`,
      action: isResearcher ? 'PDFExport' : 'OrgPDFExport',
      outcome: 'Success',
    });

    try {
      await sleep(1000);
      const pdf = new jsPDF({
        format: 'letter',
        unit: 'pt',
      });

      // load fonts into the PDF
      pdf.addFileToVFS('Roboto-Regular.ttf', font);
      pdf.addFileToVFS('Roboto-MediumItalic.ttf', italicFont);
      pdf.addFileToVFS('Roboto-Bold.ttf', boldFont);
      pdf.addFont('Roboto-Regular.ttf', 'Roboto', 'normal');
      pdf.addFont('Roboto-MediumItalic.ttf', 'Roboto', 'italic');
      pdf.addFont('Roboto-Bold.ttf', 'Roboto', 'bold');

      // set the font to Roboto
      pdf.setFont('Roboto');
      const elementHTML = document.querySelector('#pdf-export');
      // jspdf cannot handle svgs
      var svgs = elementHTML.getElementsByTagName('svg');
      if (svgs) {
        for (const svg of svgs) {
          svg.remove();
        }
      }
      pdf.html(elementHTML, {
        callback: function(doc) {
          const fileName = `SciLeads-PDF-Export-${format(
            new Date().toString(),
            'DD-MMM-YY-HH-mm'
          )}.pdf`;
          // All page numbers and links to the portal
          for (var i = 2; i <= doc.internal.getNumberOfPages(); i++) {
            doc.setPage(i);

            doc.text(
              `Page  ${i - 1} of ${doc.internal.getNumberOfPages() - 1}`,
              520,
              30
            );
            doc.link(450, 750, 150, 100, {
              url: 'https://portal.scileads.com',
            });
            doc.link(0, 0, 170, 60, {
              url: 'https://scileads.com',
            });
          }
          doc.save(fileName);
        },
        x: 5,
        y: 1,
        width: 145, //target width in the PDF document
        windowWidth: 200,
      });
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setPdfExportStatus(false));
    }
  }, [dispatch, logPdfExport, term, isResearcher]);

  const mappedTopMeshTopics = useMemo(() => {
    if (!data?.visualisations?.topMeshTopics?.data) return null;
    return {
      ...data?.visualisations?.topMeshTopics,
      data: data?.visualisations?.topMeshTopics.data.map(
        (_, i) => data?.visualisations?.topMeshTopics.data.length - i
      ),
    };
  }, [data?.visualisations?.topMeshTopics]);

  useEffect(() => {
    if (isPdfExportStarted) {
      exportPdf().then(() => {});
    }
  }, [isPdfExportStarted, exportPdf]);

  if (!isPdfExportRunning) return <></>;

  const getReportTableData = (reportData, isCurrency) => {
    if (!reportData) return [];
    const res = [];
    const maxValue = Math.max(...reportData.data);
    reportData.labels.forEach((label, index) => {
      res.push({
        rank: index,
        title: label,
        count: reportData.data[index],
        totalPercentage: (reportData.data[index] / maxValue) * 100,
        totalPercentageColor: index % 2 ? `#bbe1e7` : `#ffc0ab`,
      });
    });
    return res;
  };

  const getReportTableDataFromArray = (fundingData, isCurrency) => {
    if (!fundingData) return [];
    const res = [];
    const maxValue = Math.max(...fundingData.map(f => f.value));
    fundingData.forEach((funding, index) => {
      res.push({
        rank: funding.rank,
        title: funding.key,
        count: !isCurrency
          ? funding.value
          : `$${formatCurrency(funding.value)}`,
        totalPercentage: (funding.value / maxValue) * 100,
        totalPercentageColor: index % 2 ? `#bbe1e7` : `#ffc0ab`,
      });
    });
    return res;
  };

  const getPageFooter = () => (
    <PageFooter>
      <div>{`Generated by ${user.userEmail} on ${format(
        dayjs().toJSON(),
        'MMMM Do, YYYY'
      )}`}</div>
      <div>{pdfDocumentName}</div>
    </PageFooter>
  );

  const fundingPageVisible = isResearcher;
  const publicationPageVisible = isResearcher;
  const hasVCFunding = false && !isResearcher;
  const hasOrgExhibited = !isResearcher;
  const hasTopInvestors = false && !isResearcher;

  return (
    <ExportContainer>
      <ExportPages id={'pdf-export'}>
        <TitlePage>
          <SciLeadsLogo height={79} />
          <div className='item'>
            <Title
              style={{ textAlign: 'center', borderBottom: '4px solid #f6f3ee' }}
            >
              PDF export for search term:
            </Title>
            <SearchTerm>
              {term && term.length > 900 ? term.slice(0, 900) + '...' : term}
            </SearchTerm>
          </div>
          <Stats className='item'>
            <SubTitle>Summary:</SubTitle>
            <div className='statistics'>
              <PdfStatistic
                title='Funding Awards'
                icon={<FundingIcon />}
                value={data.totalFunding}
              />
              <PdfStatistic
                title='Tradeshow Sessions'
                icon={<TradeshowIcon />}
                value={data.totalPosters}
              />
              <PdfStatistic
                title='Published Articles'
                icon={<ArticlesIcon />}
                value={data.totalPublications}
              />
              <PdfStatistic
                title='Clinical Trials'
                icon={<ClinicalIcon />}
                value={data.totalClinicalTrials}
              />
              <PdfStatistic
                title='People'
                icon={<EmailIcon />}
                value={data.distinctSuperResearchersCount}
              />
              <PdfStatistic
                title='Organisations'
                icon={<OrganisationIcon />}
                value={data.organisationCount}
              />
            </div>
          </Stats>
        </TitlePage>

        <PageContainer>
          <PageHeader>
            <SciLeadsLogo height={50} />
          </PageHeader>
          <>
            <Title>{`${
              isResearcher ? 'Researcher' : 'Organisation'
            } Tradeshows`}</Title>
            <HorizontalReportTable
              countLabel={'Count'}
              reportData={getReportTableData(
                data?.visualisations?.topTradeshows
              )}
              type={'Tradeshow'}
            />
            {getPageFooter()}
          </>
        </PageContainer>

        {hasVCFunding && (
          <PageContainer>
            <PageHeader>
              <SciLeadsLogo height={50} />
            </PageHeader>
            <Title>Organisation VC Funding</Title>
            <HorizontalReportTable
              countLabel={'Funding (USD)'}
              reportData={getReportTableData(
                data?.visualisations?.topVCFunding,
                true
              )}
              type={'VC Funding'}
              currency={true}
            />
            {getPageFooter()}
          </PageContainer>
        )}

        {hasOrgExhibited && (
          <PageContainer>
            <PageHeader>
              <SciLeadsLogo height={50} />
            </PageHeader>
            <Title>Organisation Exhibitions</Title>
            <HorizontalReportTable
              countLabel={'Count'}
              reportData={getReportTableData(
                data?.visualisations?.topExhibited
              )}
              type={'Exhibited'}
            />
            {getPageFooter()}
          </PageContainer>
        )}

        {isResearcher && (
          <PageContainer>
            <PageHeader>
              <SciLeadsLogo height={50} />
            </PageHeader>
            <>
              <Title>Top Focused Research Areas</Title>
              <HorizontalReportTable
                countLabel={'Count'}
                type={'Research Area'}
                reportData={getReportTableData(mappedTopMeshTopics)}
                excludeCount={true}
                excludeRank={true}
              />
            </>
            {!!user.showAdditionalGraphs && isResearcher && (
              <>
                <Title>Activity Trend</Title>
                <Publications
                  hideLable={true}
                  hideHelpText={true}
                  animate={false}
                  data={data?.visualisations?.researchersByYear}
                />
              </>
            )}
            {getPageFooter()}
          </PageContainer>
        )}

        <PageContainer>
          <PageHeader>
            <SciLeadsLogo height={50} />
          </PageHeader>
          <>
            <Title>{`${
              isResearcher ? 'Researcher' : 'Organisation'
            } Regions`}</Title>
            <HorizontalReportTable
              type={'Regions'}
              countLabel={'Count'}
              reportData={getReportTableData(data?.visualisations?.regions)}
            />
            {getPageFooter()}
          </>
        </PageContainer>
        <PageContainer>
          <PageHeader>
            <SciLeadsLogo height={50} />
          </PageHeader>
          <>
            <Title>{`${
              isResearcher ? 'Researcher' : 'Organisation'
            } Countries`}</Title>
            <HorizontalReportTable
              type={'Countries'}
              countLabel={'Count'}
              reportData={getReportTableData(
                data?.visualisations?.topCountries
              )}
            />
            {getPageFooter()}
          </>

          <>
            <Title>{`${
              isResearcher ? 'Researcher' : 'Organisation'
            } US States`}</Title>
            <HorizontalReportTable
              type={'States'}
              countLabel={'count'}
              reportData={getReportTableData(data?.visualisations?.topUsStates)}
            />
          </>
        </PageContainer>

        {fundingPageVisible && (
          <PageContainer>
            <PageHeader>
              <SciLeadsLogo height={50} />
            </PageHeader>
            {!!user.showAdditionalGraphs && (
              <>
                <Title>Funding by Year</Title>
                <Funding
                  hideLable={true}
                  hideHelpText={true}
                  animate={false}
                  data={data?.visualisations?.fundingByYear}
                />
              </>
            )}
            <>
              <Title>Funding by Organisation</Title>
              <HorizontalReportTable
                countLabel={'Funding (USD)'}
                reportData={getReportTableDataFromArray(
                  data?.visualisations?.tables?.fundingByOrganisation
                )}
                type={'Organisation'}
                currency={true}
              />
              {getPageFooter()}
            </>
          </PageContainer>
        )}

        {publicationPageVisible && (
          <PageContainer>
            <PageHeader>
              <SciLeadsLogo height={50} />
            </PageHeader>
            {!!user.showAdditionalGraphs && (
              <>
                <Title>Publications by Year</Title>
                <Publications
                  hideLable={true}
                  hideHelpText={true}
                  animate={false}
                  data={data?.visualisations?.publicationsByYear}
                />
              </>
            )}

            <Title>Journals</Title>
            <HorizontalReportTable
              countLabel={'Count'}
              reportData={getReportTableDataFromArray(
                data?.visualisations?.tables?.topJournals,
                false
              )}
              type={'Journal'}
            />
            {getPageFooter()}
          </PageContainer>
        )}

        {hasTopInvestors && (
          <PageContainer>
            <PageHeader>
              <SciLeadsLogo height={50} />
            </PageHeader>
            <>
              <Title>Top Investors</Title>
              <HorizontalReportTable
                countLabel={'Count'}
                reportData={getReportTableDataFromArray(
                  data?.visualisations?.tables?.topInvestors
                )}
                type={'Investors'}
              />
              {getPageFooter()}
            </>
          </PageContainer>
        )}
      </ExportPages>
    </ExportContainer>
  );
};

export default PdfReport;
