import { notification } from 'antd';

export const ewrcaNotification = {
  message: 'Cannot Process',
  description:
    'This export is too large to process, please refine your search and try again.',
  placement: 'bottomLeft',
};

export const ewrcaProfileNotification = {
  message: 'Cannot Process',
  description:
    'This export is too large to process. Please reduce the email count to below 500 third party emails.',
  placement: 'bottomLeft',
};

export const exportNotification = largeExport => ({
  message: 'Generating Report',
  description: `We are generating your report and will notify you ${
    largeExport ? 'via email' : ''
  } when it is ready.`,
  placement: 'bottomLeft',
});

export const handleExportNotification = (
  ewrca,
  largeExport,
  onClose,
  isProfileCategorySelected
) => {
  const toOpen = ewrca
    ? isProfileCategorySelected
      ? ewrcaProfileNotification
      : ewrcaNotification
    : exportNotification(largeExport);
  notification.open({ ...toOpen, onClose });
};
