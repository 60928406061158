import { Button, Divider, Select as AntSelect, Space } from 'antd';
import { useEffect, useRef, useState } from 'react';

import { colours } from 'utils/theme';
import styled from 'styled-components';
import { useGet } from 'hooks';
import Loader from './Loader';
import debounce from 'lodash.debounce';

const { Option } = AntSelect;

const Container = styled.div`
  .button-add-to-new-list {
    color: ${colours.linkBlue};
    font-weight: bold;
    background: transparent;
  }
  .button-add-to-new-list:hover {
    background: ${colours.linkBlue};
    border-color: ${colours.linkBlue};
    color: white;
  }
  .button-add-to-new-list:focus {
    background: transparent;
    color: ${colours.linkBlue};
  }
  .button-add-to-new-list:active {
    background: transparent;
    color: ${colours.linkBlue};
  }
`;

const renderOptions = (dataTestId, renderOption, options) => {
  return options?.map(
    renderOption
      ? renderOption
      : (o, i) => (
          <Option
            title={o.label}
            key={`${o.value}-${i}`}
            value={o.value}
            data-testid={`${dataTestId}-option-${i}`}
            details={o}
          >
            {o.label}
          </Option>
        )
  );
};

const Select = ({
  options = [],
  renderOption,
  'data-testid': dataTestId,
  onCreateNewItem,
  buttonText,
  antSelectRef,
  ...rest
}) => {
  const refButton = useRef();
  const refSelect = useRef();
  const refContainer = useRef();
  const refSpace = useRef();

  const [{ res, loading }, getOptions] = useGet({
    url: options,
  });

  const debounced = debounce(
    value => getOptions({ query: { name: value } }),
    300
  );
  const [localOptions, setLocalOptions] = useState(
    typeof options !== 'string' ? options : []
  );

  useEffect(() => {
    if (res && res !== localOptions) {
      setLocalOptions(res);
    }
  }, [res, localOptions]);

  useEffect(() => {
    if (options && typeof options !== 'string' && options !== localOptions) {
      setLocalOptions(options);
    }
  }, [options, localOptions]);

  const onInputChange = value => {
    if (typeof options === 'string') {
      debounced(value);
    }
  };

  return (
    <AntSelect
      {...rest}
      ref={refSelect}
      onSearch={onInputChange}
      data-testid={dataTestId}
      notFoundContent={loading ? <Loader /> : null}
      dropdownRender={menu => (
        <>
          {menu}
          {onCreateNewItem != null && (
            <>
              <Divider style={{ margin: '2px 0' }}></Divider>
              <Space
                direction='horizontal'
                style={{ width: '100%', justifyContent: 'center' }} // background: '#f7f3ed'
              >
                <Container ref={refContainer}>
                  <Button
                    ref={refButton}
                    className='button-add-to-new-list'
                    onClick={() => {
                      refButton.current && refButton.current.blur();
                      refContainer.current && refContainer.current.blur();
                      refSpace.current && refSpace.current.blur();
                      refSelect.current && refSelect.current.blur();
                      onCreateNewItem();
                    }}
                    type='text'
                    size='small'
                    style={{ margin: '0 0px 1px 0' }}
                  >
                    {buttonText}
                  </Button>
                </Container>
              </Space>
            </>
          )}
        </>
      )}
    >
      {renderOptions(dataTestId, renderOption, localOptions)}
    </AntSelect>
  );
};

export default Select;
