import { useEffect, useState } from 'react';
import { Alert, Modal as AntModal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { usePost } from 'hooks';
import { Input, Select, Detail, Loader } from 'components/common';
import { userAlerts, sanityCheckAlert } from 'services/api';
import { getAlertToCreate } from 'selectors/alerts';
import { setAlertToCreate } from 'actions/alerts';
import { frequencyOptions } from 'models/frequency';

const CreateModal = ({ onCreateSuccess }) => {
  const dispatch = useDispatch();
  const activeAlert = useSelector(getAlertToCreate);
  const [frequency, setFrequency] = useState(null);

  const [
    {
      res: recentItemsResults,
      loading: sanityCheckLoading,
      error: sanityError,
    },
    getUserAlerts,
  ] = usePost({
    url: sanityCheckAlert,
  });

  useEffect(() => {
    getUserAlerts({
      ...activeAlert,
      searchFilters: {
        lists: activeAlert.lists,
        excludeLists: activeAlert.excludeLists,
        organisationLists: activeAlert.organisationLists,
        organisationExcludeLists: activeAlert.organisationExcludeLists,
      },
      nestedQueries: activeAlert.nestedQueries,
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [{ res: success, loading: creating, error }, createAlert] = usePost({
    url: userAlerts,
  });

  useEffect(() => {
    if (success && frequency !== null) {
      onCreateSuccess(activeAlert);
      dispatch(setAlertToCreate());
      setFrequency(null);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, onCreateSuccess, dispatch, frequency, activeAlert]);

  const handleCreateChange = key => value => {
    const updatedAlert = {
      ...activeAlert,
      [key]: value,
    };

    dispatch(setAlertToCreate(updatedAlert));
  };

  const handleCancel = () => {
    dispatch(setAlertToCreate());
  };

  const handleCreate = () => {
    const frequency = frequencyOptions.find(
      freq => freq.value === activeAlert.frequency
    ).enumValue;
    setFrequency(frequency);
    createAlert({
      ...activeAlert,
      lists: activeAlert.lists?.map(l => l.id) || [],
      excludeLists: activeAlert.excludeLists?.map(l => l.id) || [],
      organisationLists: activeAlert.organisationLists?.map(l => l.id) || [],
      organisationExcludeLists:
        activeAlert.organisationExcludeLists?.map(l => l.id) || [],
      frequency,
      recentItemsResults: recentItemsResults,
    });
  };

  if (!activeAlert) return null;

  const isValid =
    activeAlert.name && activeAlert.monthlyKeywords && activeAlert.frequency;

  return (
    <AntModal
      open
      title='Save Search'
      onCancel={handleCancel}
      okText='Save'
      onOk={handleCreate}
      okButtonProps={{
        disabled: creating || !isValid || creating,
        hidden: sanityCheckLoading || sanityError,
        'data-testid': 'create-save-button',
      }}
      cancelButtonProps={{
        disabled: creating,
        'data-testid': 'create-cancel-button',
      }}
    >
      {sanityCheckLoading ? (
        <Loader />
      ) : sanityError ? (
        <div />
      ) : (
        <div>
          <Detail label='Search Name'>
            <Input
              showCount
              disabled={creating}
              maxLength={50}
              value={activeAlert.name}
              onChange={handleCreateChange('name')}
            />
          </Detail>
          <Detail label='Alert Frequency'>
            <Select
              disabled={creating}
              style={{ width: '100%' }}
              value={activeAlert.frequency}
              onChange={handleCreateChange('frequency')}
              options={frequencyOptions}
            />
          </Detail>
        </div>
      )}

      {sanityError && (
        <Alert
          message={
            'This is not a valid search, please correct search before saving again.'
          }
          type='error'
        />
      )}
      {error && <Alert message={error.message?.message ?? ''} type='error' />}
    </AntModal>
  );
};

export default CreateModal;
