import storage from 'services/storage';

export const types = {
  SET_VIEW: 'LISTS_SET_VIEW',
  SET_MODAL: 'LISTS_SET_MODAL',
  SET_ACTIVE_LIST: 'LISTS_SET_ACTIVE_LIST',
  SET_SHOW_MY_LISTS_ONLY: 'LISTS_SET_SHOW_MY_LISTS_ONLY',
  SET_INCLUDE_PAUSED_LISTS: 'LISTS_SET_INCLUDE_PAUSED_LISTS',
  SET_SELECTED_MEMBERS: 'LISTS_SET_SELECTED_MEMBERS',
  SET_PAGE_SIZE: 'LISTS_SET_PAGE_SIZE',
  SET_PAGE_NUMBER: 'LISTS_SET_PAGE_NUMBER',
  SET_SORT: 'LISTS_SET_SORT',
  SET_ADD_TO_TARGET: 'LISTS_SET_ADD_TO_TARGET',
  SET_LIST_MEMBERS: 'LISTS_SET_LIST_MEMBERS',
  RESTORE_STATE: 'LISTS_RESTORE_STATE',
  CLEAR_SELECTED_MEMBERS: 'LISTS_CLEAR_SELECTED_MEMBERS',
};

const persistState = async (dispatch, getState) => {
  const state = getState();
  const toSave = JSON.parse(JSON.stringify(state)).lists;
  delete toSave.modal;
  delete toSave.organisation.listMembers;
  delete toSave.organisation.showMyListsOnly;
  delete toSave.researcher.listMembers;
  delete toSave.researcher.showMyListsOnly;
  await storage.setItem('listsV2', toSave);
};

export const setModal = modal => ({
  type: types.SET_MODAL,
  payload: { modal },
});

export const setActiveList = (
  list,
  options = { clearSelectedMembers: true }
) => async dispatch => {
  await dispatch({
    type: types.SET_ACTIVE_LIST,
    payload: { list },
  });
  if (options.clearSelectedMembers) {
    await dispatch(setSelectedMembers({ exclude: {}, include: {} }));
  }
  await dispatch(persistState);
};

export const setShowMyListsOnly = show => ({
  type: types.SET_SHOW_MY_LISTS_ONLY,
  payload: { show },
});

export const setIncludePausedLists = include => ({
  type: types.SET_INCLUDE_PAUSED_LISTS,
  payload: { include },
});

export const setSelectedMembers = selected => async dispatch => {
  await dispatch({
    type: types.SET_SELECTED_MEMBERS,
    payload: { selected },
  });
  await dispatch(persistState);
};

export const setPageSize = size => async dispatch => {
  await dispatch({
    type: types.SET_PAGE_SIZE,
    payload: { size },
  });
  await dispatch(persistState);
};

export const setPageNumber = number => async dispatch => {
  await dispatch({
    type: types.SET_PAGE_NUMBER,
    payload: { number },
  });
  await dispatch(persistState);
};

export const setSort = sortKey => async dispatch => {
  await dispatch({
    type: types.SET_SORT,
    payload: { sortKey },
  });
  await dispatch(persistState);
};

export const setAddToTarget = list => ({
  type: types.SET_ADD_TO_TARGET,
  payload: { list },
});

export const setListMembers = members => ({
  type: types.SET_LIST_MEMBERS,
  payload: { members },
});

export const restoreState = state => ({
  type: types.RESTORE_STATE,
  payload: state,
});

export const clearSelectedMembers = () => async dispatch => {
  await dispatch(setSelectedMembers({ exclude: {}, include: {} }));
};

export const setView = view => async dispatch => {
  dispatch({
    type: types.SET_VIEW,
    payload: view,
  });
  dispatch(persistState);
};
