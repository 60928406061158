import { useContext } from 'react';
import { Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useGet } from 'hooks';

import { Token } from 'components/common/TokenInput';
import { Pane, Button, Icon } from 'components/common';
import { colours } from 'utils/theme';
import {
  purchasers,
  purchasingCategoriesRemote,
  purchaserCountries,
  purchaserStates,
  suppliers,
} from 'services/inputTokens';
import { performSearch, resetFilters } from 'actions/purchasing';
import { getFilter } from 'selectors/purchasing';

import Filter from './helpers/FilterField';
import { FiltersContainer, FilterActions, FilterWrapper } from './styled';
import { PurchasingContext } from './';

const Help = () => (
  <Tooltip
    title='Search filters can help you easily refine your search to better target the results you are after.'
    placement='right'
  >
    <div style={{ cursor: 'pointer' }}>
      <Icon icon='help' color={colours.teal} size='xs' />
    </div>
  </Tooltip>
);

const Filters = ({ loading }) => {
  const postSearch = useContext(PurchasingContext);
  const dispatch = useDispatch();
  const statesFilter = useSelector(getFilter('purchaserState'));
  const [{ res: countriesResponse, loading: countriesLoading }] = useGet({
    url: purchaserCountries,
    onMount: true,
  });
  const [
    { res: purchasingTypesResponse, loading: purchasingTypesLoading },
  ] = useGet({
    url: purchasingCategoriesRemote,
    onMount: true,
  });

  const handleReset = () => {
    dispatch(resetFilters);
  };

  const handleUpdate = () => {
    dispatch(performSearch(postSearch, { newSearch: true }));
  };

  const updateButton = (
    <Button
      type='primary'
      data-testid='update-filter'
      onClick={handleUpdate}
      style={{ marginLeft: '10px' }}
      disabled={loading}
    >
      Search
    </Button>
  );

  return (
    <FilterWrapper>
      <Pane title='Search Filters' headerRight={<Help />}>
        <FiltersContainer>
          <Filter
            filterType='select'
            filterId='purchaserType'
            placeholder='Purchaser Type'
            options={
              purchasingTypesLoading
                ? []
                : purchasingTypesResponse.map(o => ({
                    label: o.categoryName,
                    value: o.categoryName,
                  }))
            }
            mode={'multiple'}
            style={{ width: '100%' }}
            tagRender={tagRender}
            disabled={loading}
          />
          <Filter
            filterType='token'
            filterId='purchaserName'
            placeholder='Purchaser Name'
            options={purchasers}
            disabled={loading}
          />
          <Filter
            allowClear={false}
            filterType='select'
            filterId='purchaserCountry'
            placeholder='Purchaser Country'
            options={
              countriesLoading
                ? []
                : countriesResponse.map(o => ({
                    label: o.countryName,
                    value: o.countryName,
                  }))
            }
            displayKey='displayName'
            onTheFly={false}
            mode={'multiple'}
            style={{ width: '100%' }}
            tagRender={tagRender}
            disabled={loading}
          />
          <Filter
            filterType='token'
            filterId='purchaserState'
            placeholder='Purchaser State'
            options={purchaserStates}
            displayKey='displayName'
            onTheFly={false}
            otherRemoteParams={{
              excludeStateIds:
                statesFilter?.map(x => x?.id)?.filter(x => x) ?? [],
            }}
            disabled={loading}
          />
          <Filter
            filterType='token'
            filterId='purchaserZip'
            placeholder='Purchaser Zip'
            disabled={loading}
          />
          <Filter
            filterType='token'
            filterId='supplierName'
            placeholder='Supplier Name'
            options={suppliers}
            disabled={loading}
          />
          <Filter
            filterType='token'
            filterId='item'
            placeholder='Item'
            disabled={loading}
          />
          <Filter
            filterType='datePicker'
            filterId='date'
            type='range'
            placeholder={['Purchase Date From', 'Purchase Date To']}
            disabled={loading}
          />
          <Filter
            filterType='number'
            filterId='minPrice'
            min={0}
            placeholder={'Min. Price (USD)'}
            disabled={loading}
          />
          <Filter
            filterType='number'
            filterId='maxPrice'
            min={0}
            placeholder={'Max. Price (USD)'}
            disabled={loading}
          />
          <Filter filterType='checkbox' filterId='hasPrice' disabled={loading}>
            PO Includes Price
          </Filter>
          <FilterActions>
            <Button
              data-testid='reset-filter'
              onClick={handleReset}
              disabled={loading}
            >
              Reset
            </Button>
            {updateButton}
          </FilterActions>
        </FiltersContainer>
      </Pane>
    </FilterWrapper>
  );
};

const tagRender = props => {
  const { label, onClose } = props;
  const onPreventMouseDown = event => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Token
      key={label}
      value={label}
      removeToken={onClose}
      style={{ marginTop: 'auto', marginBottom: 'auto' }}
      onMouseDown={onPreventMouseDown}
    />
  );
};

Filters.propTypes = {
  loading: PropTypes.bool,
};

export default Filters;
