import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';

import { useAuth, usePost } from 'hooks';
import { Button, NetPromoterScore } from 'components/common';
import { largeWarning, exportSearch } from 'services/api';
import {
  getSearchRequest,
  getHasResults,
  getLastSuccessfulSearch,
  getSearchView,
  getBlockedForExport,
  isPdfExportRunning,
} from 'selectors/search';

import { setPdfExportStatus } from 'actions/search';
import licenseType from 'models/licenseType';
import { DisabledButtonWrapper } from 'components/common/styled.js';
import ListAction from './List';
import { ActionsContainer } from '../styled';
import { handleExportNotification } from './notifications';
import { searchOrigin, view as viewConstants } from '../constants';
import { OrganisationSearch, ResearcherSearch } from '../helpers';

const Actions = props => {
  const { hasLicense, user } = useAuth();
  const dispatch = useDispatch();
  const [canUseNps, setCanUseNps] = useState(false);
  const [{ loading }, postExport] = usePost({});
  const { res: searchRes, loading: searching } = useSelector(getSearchRequest);
  const hasResults = useSelector(getHasResults);
  const isPdfExportStarted = useSelector(isPdfExportRunning);
  const lastSuccessfulSearch = useSelector(getLastSuccessfulSearch);
  const view = useSelector(getSearchView);
  const blockedForExport = useSelector(getBlockedForExport);

  const handleOpenNps = () => {
    setCanUseNps(true);
  };

  const handleDismissNps = () => {
    setCanUseNps(false);
  };

  const handleExport = () => {
    const { largeExport, ewrca, profileEmailCount } = searchRes;
    const {
      query,
      lists,
      excludeLists,
      nestedQueries,
      organisationLists,
      organisationExcludeLists,
    } = lastSuccessfulSearch;
    postExport({
      url: ewrca ? largeWarning('UniversalSearch') : exportSearch,
      body: {
        query,
        orderBy: 'desc',
        largeExport,
        filters: {
          lists,
          excludeLists,
          organisationLists,
          organisationExcludeLists,
        },
        isOrganisationExport: OrganisationSearch(view),
        nestedQueries,
        searchOrigin: !ResearcherSearch(view)
          ? searchOrigin.organisationSearch
          : searchOrigin.researcherSearch,
      },
    });
    handleExportNotification(
      ewrca,
      largeExport,
      handleOpenNps,
      profileEmailCount > 0
    );
  };

  const handlePdfExport = () => {
    dispatch(setPdfExportStatus(true));
  };

  const exportButton = (
    <Button
      size='small'
      onClick={handleExport}
      disabled={
        loading ||
        searching ||
        !lastSuccessfulSearch.query ||
        !hasResults ||
        blockedForExport
      }
    >
      Export Data
    </Button>
  );

  const pdfExportButton = (
    <Button
      style={{ marginLeft: 5 }}
      size='small'
      loading={isPdfExportStarted}
      onClick={handlePdfExport}
      disabled={
        loading ||
        searching ||
        !lastSuccessfulSearch.query ||
        (!hasResults && hasResults) ||
        blockedForExport ||
        isPdfExportStarted
      }
    >
      {isPdfExportStarted ? ' Generating PDF...' : 'Export PDF'}
    </Button>
  );

  const canExport =
    hasLicense(
      [
        licenseType.temporaryOld,
        licenseType.legacy,
        licenseType.academic,
        licenseType.industry,
      ],
      false
    ) &&
    !user.isRateLimited &&
    !user.isRateLimited;

  return (
    <ActionsContainer>
      <ListAction {...props} />
      {canExport &&
        (blockedForExport ? (
          <div className='exportOptions'>
            <Tooltip
              placement='topRight'
              title='You need to update the search to reactivate export'
            >
              <DisabledButtonWrapper>{exportButton}</DisabledButtonWrapper>
            </Tooltip>
          </div>
        ) : (
          <div className='exportOptions'>
            {exportButton}
            {(view === viewConstants.visualisation ||
              view === viewConstants.organisationInsights) &&
              pdfExportButton}
          </div>
        ))}
      {canUseNps && <NetPromoterScore onDismiss={handleDismissNps} />}
    </ActionsContainer>
  );
};

export default Actions;
