import styled from 'styled-components';

import { colours } from 'utils/theme';

const Container = styled.div`
  margin-bottom: 5px;
`;

const Detail = ({ children, label, className, style }) => {
  return (
    <Container className={className} style={style}>
      <div
        style={{
          fontSize: 11,
          textTransform: 'uppercase',
          fontWeight: 'bold',
          color: colours.grey,
        }}
      >
        {label}
      </div>
      {children || '-'}
    </Container>
  );
};

export default Detail;
