import { Form, Input, Button } from 'components/common';
import styled from 'styled-components';
import { useState, useEffect } from 'react';

import PasswordError from '../../Settings/Account/PasswordError';

import { passwordCheck } from 'services/api';
import { usePost } from 'hooks';

import { Icon } from 'components/common';

const NewPassword = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PasswordRequirementsInfo = styled.p`
  margin-bottom: 0em;
  font-size: 12px !important;
`;

const PasswordChange = ({
  serverError,
  customError,
  onChange,
  newPass,
  setNewPass,
  handleSubmit,
  loading,
  setCustomError,
  hideChangePasswordButton,
  confirmPass,
  setConfirmPass,
}) => {
  const handleClientSidePasswordErrorChange = (
    hasErrors,
    hasPasswordNotMatchingError,
    passwordTooShortError,
    passwordTooWeakError
  ) => {
    onChange(hasErrors || !passwordCheckRes);
    setHasErrors(hasErrors);
    setHasPasswordNotMatchingError(hasPasswordNotMatchingError);
    setPasswordTooWeakError(passwordTooWeakError);
    setPasswordTooShortError(passwordTooShortError);
    setHasValidatedErrors(true);
  };

  const [previousNewPass, setPreviousNewPass] = useState('');

  const [hasValidatedErrors, setHasValidatedErrors] = useState(false);

  const [
    hasUserUnfocusedNewPasswordField,
    setHasUserUnfocusedNewPasswordField,
  ] = useState(false);
  const [
    hasUserFocusedCurrentPasswordField,
    setHasUserFocusedConfirmPasswordField,
  ] = useState(false);

  const [
    hasPasswordNotMatchingError,
    setHasPasswordNotMatchingError,
  ] = useState(false);

  const [passwordTooWeakError, setPasswordTooWeakError] = useState(false);

  const [passwordTooShortError, setPasswordTooShortError] = useState(false);

  const [hasErrors, setHasErrors] = useState(false);

  const [
    { error: passCheckError, res: passwordCheckRes },
    postPasswordCheck,
  ] = usePost({
    url: passwordCheck,
  });

  const handleNewPasswordUnfocus = () => {
    setHasValidatedErrors(false);
    setHasUserUnfocusedNewPasswordField(true);
  };

  useEffect(() => {
    if (
      hasValidatedErrors &&
      hasUserUnfocusedNewPasswordField &&
      !passwordTooShortError &&
      !passwordTooWeakError &&
      newPass !== previousNewPass &&
      newPass !== ''
    ) {
      setPreviousNewPass(newPass);
      postPasswordCheck({ password: newPass });
    }
  }, [
    hasValidatedErrors,
    hasUserUnfocusedNewPasswordField,
    passwordTooShortError,
    passwordTooWeakError,
    newPass,
    previousNewPass,
    postPasswordCheck,
  ]);

  const disableButton =
    !newPass || !confirmPass || loading || hasErrors || passCheckError;

  const handleFormSubmit = () => {
    handleSubmit();
  };

  useEffect(() => {
    if (passwordCheckRes) {
      setCustomError(null);
    }
    if (passCheckError) {
      setCustomError(
        'This password is not secure. Please choose a different password.'
      );
    }
  }, [setCustomError, passwordCheckRes, passCheckError]);

  return (
    <Form canSubmit={!disableButton} onSubmit={handleSubmit}>
      <NewPassword>
        <PasswordRequirementsInfo>
          <strong>New password requirements:</strong>
          <br />
          <span>
            {newPass !== '' && hasUserUnfocusedNewPasswordField ? (
              passwordTooShortError ? (
                <Icon icon='invalid' style={{ color: 'red' }} size='1x' />
              ) : (
                <Icon icon='valid' style={{ color: 'green' }} size='1x' />
              )
            ) : (
              <Icon icon='toBeValidated' style={{ color: 'grey' }} size='1x' />
            )}{' '}
            8 or more characters
            <br />
          </span>
          <span>
            {newPass !== '' && hasUserUnfocusedNewPasswordField ? (
              passwordTooWeakError ? (
                <Icon icon='invalid' style={{ color: 'red' }} size='1x' />
              ) : (
                <Icon icon='valid' style={{ color: 'green' }} size='1x' />
              )
            ) : (
              <Icon icon='toBeValidated' style={{ color: 'grey' }} size='1x' />
            )}{' '}
            3 of the following: uppercase, lowercase, number, special character
            (!, #, @, $, %)
            <br />
          </span>
          <span>
            {newPass !== '' &&
            (passCheckError || passwordCheckRes) &&
            hasUserUnfocusedNewPasswordField ? (
              customError || passwordTooWeakError || passwordTooShortError ? (
                <Icon icon='invalid' style={{ color: 'red' }} size='1x' />
              ) : (
                <Icon icon='valid' style={{ color: 'green' }} size='1x' />
              )
            ) : (
              <Icon icon='toBeValidated' style={{ color: 'grey' }} size='1x' />
            )}{' '}
            Complex password
            <br />
          </span>
          <span>
            {confirmPass !== '' &&
            newPass !== '' &&
            hasUserUnfocusedNewPasswordField ? (
              hasPasswordNotMatchingError ? (
                <Icon icon='invalid' style={{ color: 'red' }} size='1x' />
              ) : (
                <Icon icon='valid' style={{ color: 'green' }} size='1x' />
              )
            ) : (
              <Icon icon='toBeValidated' style={{ color: 'grey' }} size='1x' />
            )}{' '}
            New passwords match
          </span>
        </PasswordRequirementsInfo>
        <Input
          password
          placeholder='New Password'
          value={newPass}
          onChange={setNewPass}
          onFocus={() => setHasUserUnfocusedNewPasswordField(false)}
          onBlur={handleNewPasswordUnfocus}
        />
        <Input
          password
          placeholder='Confirm New Password'
          value={confirmPass}
          onChange={setConfirmPass}
          onFocus={() => setHasUserFocusedConfirmPasswordField(true)}
          onBlur={() => setHasUserFocusedConfirmPasswordField(false)}
        />
        <PasswordError
          confirmPass={confirmPass}
          newPass={newPass}
          shouldRender={
            hasUserUnfocusedNewPasswordField ||
            hasUserFocusedCurrentPasswordField
          }
          serverError={serverError}
          onChange={handleClientSidePasswordErrorChange}
          passwordCheckFunction={postPasswordCheck}
        />
        {!hideChangePasswordButton && (
          <Button
            visible={hideChangePasswordButton}
            type='primary'
            disabled={disableButton}
            onClick={handleFormSubmit}
          >
            Change Password
          </Button>
        )}
      </NewPassword>
    </Form>
  );
};

export default PasswordChange;
