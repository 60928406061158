import styled from 'styled-components';
import { Fragment } from 'react';

import { Button, Icon, Table, Tooltip } from 'components/common';
import { colours } from 'utils/theme';
import { format } from 'utils/date';
import { crmTypesToStringMap } from 'utils/helpers/crm';
import { integrationsImageMap } from './LicenseAllocation';
import { actionErrors } from './constants';

const getLastActivityDate = date =>
  date ? format(date, 'Do MMM YYYY') : "Hasn't logged in";

const StyledTable = styled(Table)`
  margin-top: 10px;
`;

const Help = ({ error }) => {
  return (
    <Tooltip title={error} placement='right' overlayStyle={{ maxWidth: 360 }}>
      <div style={{ cursor: 'pointer' }}>
        <Icon icon='help' color={colours.teal} size='lg' />
      </div>
    </Tooltip>
  );
};

const Actions = ({ user, setModal }) => {
  if (user.currentUser) return null;
  if (!user.enabled) return <Help error={actionErrors.disabled} />;
  if (!user.licenses?.length) return <Help error={actionErrors.noLicenses} />;
  if (user.isLicenseExpired) return <Help error={actionErrors.expired} />;
  return (
    <>
      <Button
        onClick={() => setModal('addUser', user)}
        size='small'
        style={{ marginRight: 10 }}
      >
        Edit
      </Button>
      <Button onClick={() => setModal('password', user)} size='small'>
        Reset Password
      </Button>
    </>
  );
};

const UserTable = ({ data, setModal }) => {
  const config = {
    headers: [
      { label: 'Status', key: 'status' },
      { label: 'Name', key: 'fullname' },
      { label: 'Email', key: 'email' },
      {
        label: (
          <div style={{ lineHeight: '12px' }}>
            Last Activity<div style={{ fontSize: 9 }}>within last 30 days</div>
          </div>
        ),
        key: 'lastActivityDate',
        testId: 'lastActivityDate',
      },
      { label: 'Licenses', key: 'userLicense' },
      { label: 'Features', key: 'features' },
      '',
    ],
    createRow: (r, i) => (
      <Fragment key={`${r.email}-${i}`}>
        <div style={{ paddingRight: 0, alignItems: 'center' }}>
          {r.active ? (
            <Icon icon='accept' color={colours.teal} size='1x' />
          ) : (
            <Icon icon='cancel' color={colours.red} size='1x' />
          )}
        </div>
        <div>{r.fullname}</div>
        <div>{r.email}</div>
        <div>{getLastActivityDate(r.lastActivityDate)}</div>
        <div>{r.userLicense}</div>
        <div>
          {r.integrations?.map(x => (
            <Icon
              key={x}
              title={crmTypesToStringMap[x]}
              icon={integrationsImageMap[x]}
              size='2x'
              color='#56B3C3'
            />
          ))}
        </div>
        <div
          style={{
            paddingRight: 0,
            justifyContent: 'flex-end',
            flex: '0 0 auto',
          }}
        >
          <Actions user={r} setModal={setModal} />
        </div>
      </Fragment>
    ),
  };

  return <StyledTable data={data} config={config} sortable />;
};

export default UserTable;
