export const frequencyEnums = {
  NoAlert: 0,
  Weekly: 1,
  Monthly: 2,
  Daily: 3,
};

export const frequencyOptions = [
  { value: 'No Alert', label: 'No Alert', enumValue: 0 },
  { value: 'Monthly Alert', label: 'Monthly Alert', enumValue: 2 },
  { value: 'Weekly Alert', label: 'Weekly Alert', enumValue: 1 },
  { value: 'Daily Alert', label: 'Daily Alert', enumValue: 3 },
];
