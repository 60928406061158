import storage from 'services/storage';

export const types = {
  ADD_BREADCRUMB: 'OTHER_ADD_BREADCRUMB',
  SET_BREADCRUMB: 'OTHER_SET_BREADCRUMB',
  RESTORE_STATE: 'OTHER_RESTORE_STATE',
  SET_NEW_TAB_BREADCRUMB: 'OTHER_SET_NEW_TAB_BREADCRUMB',
  RELOAD_CRM_CONNECTIONS: 'RELOAD_CRM_CONNECTIONS',
};

export const addBreadcrumb = crumb => async dispatch => {
  await dispatch({
    type: types.ADD_BREADCRUMB,
    payload: { crumb },
  });
};

export const setBreadcrumb = crumbs => async dispatch => {
  await dispatch({
    type: types.SET_BREADCRUMB,
    payload: { crumbs },
  });
};

export const restoreState = state => async dispatch => {
  await dispatch({
    type: types.RESTORE_STATE,
    payload: state,
  });
  await storage.removeItem('other');
};

export const setNewTabBreadcrumb = crumb => async (dispatch, getState) => {
  const state = getState();
  const { breadcrumb } = state.other;

  await storage.setItem('other', { breadcrumb: [...breadcrumb, crumb] });
};

export const reloadCrmConnections = reload => async dispatch => {
  await dispatch({
    type: types.RELOAD_CRM_CONNECTIONS,
    payload: reload,
  });
};
