import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getFilter, getSearchView, getActiveFilters } from 'selectors/search';
import { Icon, Tooltip } from 'components/common';
import { OrganisationSearch } from 'components/Search/Results/helpers';
import { tradeshows, sessionTypes } from 'services/inputTokens';
import { colours } from 'utils/theme';

import {
  Filter,
  helpText,
  calculateDate,
  showTradeshowWarning,
} from '../helpers';
import OrgFilters from './OrgTradeshows';
import FilterGroup from './FilterGroup';
import { tradeshowOrganisationKeys, tradeshowResearcherKeys } from './keys';

const Title = ({ tradeshows, showValidation }) => {
  const date = useSelector(getFilter('date'));
  const [from, to] = calculateDate(date);
  const showWarning = date && showTradeshowWarning(from, to, tradeshows);

  if (showWarning) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'unset',
          flex: 1,
        }}
      >
        Tradeshows
        <Tooltip
          defaultOpen
          overlayStyle={{ maxWidth: 350 }}
          title={
            <p>
              One of the selected tradeshows falls outside the date range for
              your search.
              <br />
              <br />
              To see the results for this tradeshow select "Custom" from the
              date dropdown above the search results and extend the date to
              include the tradeshow.
            </p>
          }
        >
          <div style={{ marginLeft: 5 }}>
            <Icon icon='error' size='sm' color={colours.orange} />
          </div>
        </Tooltip>
      </div>
    );
  }

  if (showValidation && tradeshows) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flex: 1,
        }}
      >
        Tradeshows
        <Tooltip
          defaultOpen
          overlayStyle={{ maxWidth: 350 }}
          title={
            <p>
              Please select a filter below for exhibiting or presenting at a
              tradeshow, or to only search these tradeshows presentations.
            </p>
          }
        >
          <div style={{ marginLeft: 5 }}>
            <Icon icon='error' size='sm' color={colours.orange} />
          </div>
        </Tooltip>
      </div>
    );
  }

  return 'Tradeshows';
};

const Tradeshows = ({ loading }) => {
  const tradeshowValue = useSelector(getFilter('tradeshow'));
  const orgCheckBoxSelected = useSelector(getFilter('exhibitingShow'));
  const presentingSelected = useSelector(getFilter('presentingAtTradeshow'));
  const thisShowSelected = useSelector(getFilter('thisShowOnly'));

  const sessionTypeValue = useSelector(getFilter('sessionType'));

  const isOrgView = OrganisationSearch(useSelector(getSearchView));

  const activeFilters = useSelector(
    getActiveFilters(
      isOrgView
        ? Object.keys(tradeshowOrganisationKeys)
        : Object.keys(tradeshowResearcherKeys)
    )
  );

  const showValidationValue =
    !(orgCheckBoxSelected || presentingSelected || thisShowSelected) &&
    isOrgView;

  const disableThisTradeshowOnly =
    orgCheckBoxSelected && presentingSelected && isOrgView;

  const checkboxLabel =
    tradeshowValue?.length > 1
      ? 'Search selected tradeshows only'
      : 'Search selected tradeshow only';

  const sessionTypeCheckboxValue =
    sessionTypeValue?.length > 1
      ? 'Only show results with selected session types'
      : 'Only show results with selected session type';

  return (
    <FilterGroup
      icon='image'
      title={
        <Title
          tradeshows={tradeshowValue}
          showValidation={showValidationValue}
        />
      }
      activeKeys={activeFilters}
      sectionKey='tradeshows'
    >
      <>
        <Filter
          filterType='token'
          placeholder='Tradeshow Names'
          filterId={tradeshowResearcherKeys.tradeshow.key}
          options={tradeshows}
          disabledKey='dataNotAvailable'
          disabledTooltipText='Data not available yet'
          disabledChangesOnHover={true}
          helpText='Filter results by tradeshows that researchers have attended / will be attending'
          onTheFly={false}
          displayKey='label'
          optionKey='label'
          disabled={loading}
        />
        {isOrgView && (
          <OrgFilters
            filterId={[
              tradeshowOrganisationKeys.exhibitingShow.key,
              tradeshowOrganisationKeys.presentingAtTradeshow.key,
            ]}
            loading={loading}
          />
        )}
        <Tooltip
          title={helpText.thisShowOnly}
          mouseEnterDelay={0.5}
          placement='right'
          overlayStyle={{ minWidth: 450 }}
          filterId='thisShowOnly'
          disabled={loading}
        >
          <Filter
            filterType='checkbox'
            filterId={tradeshowResearcherKeys.thisShowOnly.key}
            disabled={
              !tradeshowValue ||
              tradeshowValue.length === 0 ||
              disableThisTradeshowOnly ||
              loading
            }
          >
            {checkboxLabel}
          </Filter>
        </Tooltip>
        {!isOrgView && (
          <>
            <Filter
              filterType='token'
              placeholder='Tradeshow Session Type'
              filterId={tradeshowResearcherKeys.sessionType.key}
              helpText='Filter results by session types that researchers have given'
              limit='15'
              options={sessionTypes}
              onTheFly={false}
              disabled={loading}
            />
            <Tooltip
              title={helpText.thisSessionTypeOnly}
              placement='right'
              overlayStyle={{ maxWidth: 450 }}
            >
              <Filter
                filterType='checkbox'
                filterId={tradeshowResearcherKeys.thisSessionTypeOnly.key}
                disabled={
                  !sessionTypeValue || sessionTypeValue.length === 0 || loading
                }
              >
                {sessionTypeCheckboxValue}
              </Filter>
            </Tooltip>
          </>
        )}
      </>
    </FilterGroup>
  );
};

Tradeshows.propTypes = {
  loading: PropTypes.bool,
};

export default Tradeshows;
