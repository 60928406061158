import { useSelector, useDispatch } from 'react-redux';

import { Button } from 'components/common';
import { useAuth } from 'hooks';
import { buildQuery } from 'utils/search';
import { getFilters, getSearchView } from 'selectors/search';
import { setAlertToCreate, setView } from 'actions/alerts';
import { getAlertToCreate } from 'selectors/alerts';

import { CreateModal } from 'components/Alerts/Modal';
import { Tooltip, notification } from 'antd';
import { AlertContentContainer } from '../styled';
import { useHistory } from 'react-router-dom';
import { frequencyEnums, frequencyOptions } from 'models/frequency';
import { ResearcherSearch } from '../Results/helpers';
import { searchOrigin } from '../Results/constants';
import { DisabledButtonWrapper } from 'components/common/styled';
import { RemoveNonEmptyDate } from '../Filters/keys';

const AlertContent = ({ name, frequency, push, dispatch }) => {
  const goToAlerts = () => {
    dispatch(setView('savedSearches'));
    push('/searches');
  };

  return (
    <AlertContentContainer>
      Your saved search has been created: {name}.
      <br />
      <br />
      {frequency} has been scheduled.
      <br />
      <br />
      <span onClick={goToAlerts}>Click here</span> to manage your searches.
    </AlertContentContainer>
  );
};

const Alert = ({ disabled }) => {
  const dispatch = useDispatch();
  const allFilters = useSelector(getFilters);
  const activeAlert = useSelector(getAlertToCreate);

  const view = useSelector(getSearchView);
  const { push } = useHistory();
  const {
    user: { userId },
  } = useAuth();

  const handleCreateAlert = frequency => {
    const tmpFilters = { ...allFilters };
    RemoveNonEmptyDate(tmpFilters);
    const [query, , , nestedQueries] = buildQuery(tmpFilters, view);

    dispatch(
      setAlertToCreate({
        name: null,
        monthlyKeywords: query,
        structuredSearch: JSON.stringify(tmpFilters),
        userId,
        frequency: frequencyOptions.find(f => f.enumValue === frequency).label,
        lists: allFilters.lists,
        excludeLists: allFilters.excludeLists,
        searchOrigin: !ResearcherSearch(view)
          ? searchOrigin.organisationSearch
          : searchOrigin.researcherSearch,
        organisationLists: allFilters.organisationLists,
        organisationExcludeLists: allFilters.organisationExcludeLists,
        nestedQueries,
      })
    );
  };

  const onCreateSuccess = search => {
    notification.open({
      message: 'Search saved',
      description: (
        <AlertContent
          name={search.name}
          frequency={search.frequency}
          push={push}
          dispatch={dispatch}
        />
      ),
      placement: 'bottomLeft',
    });
  };

  return (
    <>
      {disabled ? (
        <Tooltip title='Save Search'>
          <DisabledButtonWrapper>
            <Button
              type='secondary'
              data-testid='create-alert'
              size='large'
              disabled={disabled}
              onClick={() => handleCreateAlert(frequencyEnums.NoAlert)}
            >
              Save Search
            </Button>
          </DisabledButtonWrapper>
        </Tooltip>
      ) : (
        <Button
          type='secondary'
          data-testid='create-alert'
          size='large'
          onClick={() => handleCreateAlert(frequencyEnums.NoAlert)}
        >
          Save Search
        </Button>
      )}

      {activeAlert && <CreateModal onCreateSuccess={onCreateSuccess} />}
    </>
  );
};

export default Alert;
