import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';
import { Divider } from 'antd';

import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { Button, Input, Form, Checkbox } from 'components/common';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig, loginRequest } from '../../authConfig.js';
import MicrosoftLoginButton from './MicrosoftLoginButton.js';

const CredentialsForm = ({
  form,
  isLoading,
  handleLogin,
  handleChange,
  handleReset,
  handleExternalLogin,
}) => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  const msalInstance = new PublicClientApplication(msalConfig);

  const handleLoginPopup = () => {
    /**
     * For more information, please follow this link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
     */
    msalInstance
      .loginPopup({
        ...loginRequest,
        redirectUri: '/',
      })
      .then(authResponse => {
        handleExternalLogin('MICROSOFT', authResponse.idToken);
      })
      .catch(error => console.log(error));
  };

  const noDetails = !form.username || !form.password;
  return (
    <>
      <MsalProvider instance={msalInstance}>
        <GoogleOAuthProvider
          clientId={process.env.REACT_APP_PORTAL_GOOGLE_AUDIENCE}
        >
          <Form onSubmit={handleLogin}>
            <Input
              disabled={isLoading}
              ref={ref}
              onChange={handleChange('username')}
              value={form.username}
              placeholder='Email'
            />
            <Input
              disabled={isLoading}
              onChange={handleChange('password')}
              value={form.password}
              placeholder='Password'
              password
            />
            <Checkbox
              disabled={isLoading}
              checked={form.rememberMe}
              onChange={handleChange('rememberMe')}
            >
              Remember me
            </Checkbox>
          </Form>
          <Button
            disabled={isLoading || noDetails}
            onClick={handleLogin}
            type='primary'
          >
            Log In
          </Button>
          <Button disabled={isLoading} onClick={handleReset}>
            Reset Password
          </Button>
          <Divider />
          <GoogleLogin
            disabled={isLoading}
            ux_mode={'popup'}
            auto_select={false}
            useOneTap={false}
            width={250}
            onSuccess={credentialResponse => {
              handleExternalLogin('GOOGLE', credentialResponse.credential);
            }}
          />
          <MicrosoftLoginButton
            disabled={isLoading}
            onClick={handleLoginPopup}
          />
        </GoogleOAuthProvider>
      </MsalProvider>
    </>
  );
};

CredentialsForm.propTypes = {
  form: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool,
  handleLogin: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
};

CredentialsForm.defaultProps = {
  isLoading: false,
};

export default CredentialsForm;
