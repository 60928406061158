import { ProductFruits } from 'react-product-fruits';
import { licenseTypeToStringMap } from 'models/licenseType';
import { useAuth } from 'hooks';

const ProductFruit = ({ children }) => {
  const { user } = useAuth();
  const userInfo = {
    username: user.userEmail || user.email,
    email: user.userEmail || user.email,
    firstname: user.userName
      ? user.userName.split(' ')[0]
      : user.displayName.split(' ')[0],
    role:
      user.licenses?.filter(x => licenseTypeToStringMap[x.type]).length > 0
        ? user.licenses.map(x => licenseTypeToStringMap[x.type]).join(', ')
        : 'No Licenses',
  };

  return (
    <>
      <ProductFruits
        workspaceCode='lsfcYKIAsEYm2DdL'
        language='en'
        user={userInfo}
      />
      {children}
    </>
  );
};

export default ProductFruit;
