import { useEffect } from 'react';
import { Alert, Modal as AntModal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { useDel, useAuth } from 'hooks';
import { Detail } from 'components/common';
import { userAlerts } from 'services/api';
import { getAlertToDelete } from 'selectors/alerts';
import { setAlertToDelete } from 'actions/alerts';

const DeleteModal = ({ onDeleteSuccess }) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const alertToDelete = useSelector(getAlertToDelete);

  const [{ res: success, loading, error }, deleteAlert] = useDel({
    url: userAlerts,
  });

  useEffect(() => {
    if (success) {
      onDeleteSuccess();
    }
  }, [success, onDeleteSuccess, dispatch]);

  const handleDelete = () => {
    deleteAlert({
      query: {
        UserId: user.userId,
        id: alertToDelete.id,
      },
    });
  };

  const handleCancel = () => {
    dispatch(setAlertToDelete());
  };

  if (!alertToDelete) return null;

  return (
    <AntModal
      open
      title='Delete Search'
      onCancel={handleCancel}
      onOk={handleDelete}
      okButtonProps={{ disabled: loading, 'data-testid': 'delete-ok-button' }}
      cancelButtonProps={{
        disabled: loading,
        'data-testid': 'delete-cancel-button',
      }}
    >
      Do you want to delete this search?
      <Detail label='Query'>{alertToDelete.monthlyKeywords}</Detail>
      <Detail label='Alert Frequency'>{alertToDelete.frequency}</Detail>
      {error && <Alert message={error.statusText} type='error' />}
    </AntModal>
  );
};

export default DeleteModal;
