import { Fragment } from 'react';

import Table from '../../Table';
import Select from '../../Select';
import Button from '../../Button';

import { options } from './helpers';

const UserTable = ({ tableData, setTableData, listOwner, currentUser }) => {
  const removeUser = id => {
    const newTableData = tableData.filter(d => d.userId !== id);
    setTableData(newTableData);
  };

  const changeUserPerm = (id, value) => {
    tableData.find(d => d.userId === id).accessLevel = value;
    setTableData([...tableData]);
  };

  const config = {
    headers: ['User', ''],
    createRow: r => {
      const canEdit =
        r.userId !== listOwner && r.userName !== currentUser.userName;
      return (
        <Fragment key={r.userId}>
          <div>{r.userName}</div>
          <div style={{ justifyContent: 'flex-end' }}>
            <Select
              size='small'
              disabled={!canEdit}
              style={{ marginRight: 5 }}
              options={options}
              value={r.accessLevel}
              onChange={value => changeUserPerm(r.userId, value)}
            />
            {canEdit && (
              <Button size='small' onClick={() => removeUser(r.userId)} danger>
                Remove
              </Button>
            )}
          </div>
        </Fragment>
      );
    },
  };

  return <Table config={config} data={tableData} />;
};

export default UserTable;
