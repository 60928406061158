import { forwardRef } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Icon } from 'components/common';
import { addBreadcrumb, setNewTabBreadcrumb } from 'actions/other';

import { Container, LargeContainer } from './styled';

const Link = forwardRef(
  (
    {
      to,
      size,
      external,
      children,
      style,
      inline,
      showIconHint,
      onClick,
      className,
      disableLink,
      ...rest
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const ContainerComponent = size === 'large' ? LargeContainer : Container;

    const handleClick = e => {
      if (!to || to.includes('mailto') || external) return onClick();
      dispatch(
        addBreadcrumb({
          name: document.title.split(' | ')[0],
          path: pathname,
          id: Math.floor(Math.random() * Math.floor(10000)),
        })
      );
      onClick(false);
    };

    const handleIconClick = e => {
      e.stopPropagation();
      dispatch(
        setNewTabBreadcrumb({
          name: document.title.split(' | ')[0],
          path: pathname,
          id: Math.floor(Math.random() * Math.floor(10000)),
        })
      );
      onClick(true);
    };

    if (!to)
      return (
        <ContainerComponent className={className}>
          <span>{children}</span>
        </ContainerComponent>
      );

    if (external)
      return (
        <ContainerComponent
          inline={inline}
          style={style}
          {...rest}
          ref={ref}
          showIconHint={showIconHint}
          onClick={handleClick}
          className={className}
        >
          <a href={to} target='_blank' rel='noopener noreferrer'>
            {children}
            {showIconHint && <Icon className='linkIcon' icon='link' />}
          </a>
        </ContainerComponent>
      );

    if (disableLink)
      return (
        <ContainerComponent
          inline={inline}
          style={style}
          {...rest}
          ref={ref}
          showIconHint={showIconHint}
          className={className}
        >
          <span>{children}</span>
        </ContainerComponent>
      );

    return (
      <ContainerComponent
        inline={inline}
        style={style}
        ref={ref}
        showIconHint={showIconHint}
        className={className}
      >
        <RouterLink
          className='linkText'
          to={to}
          {...rest}
          onClick={handleClick}
        >
          {children}
        </RouterLink>
        <RouterLink
          className='linkIconContainer'
          to={to}
          target='_blank'
          onClick={handleIconClick}
        >
          <Icon className='linkIcon' icon='link' />
        </RouterLink>
      </ContainerComponent>
    );
  }
);

Link.defaultProps = {
  onClick: () => {},
};

export default Link;
