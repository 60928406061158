import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { setFilters } from 'actions/search';
import { getActiveFilters } from 'selectors/search';
import { journals } from 'services/inputTokens';

import { Filter } from '../helpers';
import FilterGroup from './FilterGroup';
import { advancedKeys } from './keys';

const ImpactScoreHelp = () => {
  const handleOpenFaq = () => {
    const { fcWidget } = window;
    if (fcWidget) {
      fcWidget.open();
    }
  };
  return (
    <div>
      Research Impact relates to H-Index. For more help visit our{' '}
      <span
        onClick={handleOpenFaq}
        style={{ cursor: 'pointer', color: 'rgb(24, 144, 255)' }}
      >
        FAQ
      </span>
    </div>
  );
};

const Advanced = ({ loading }) => {
  const dispatch = useDispatch();

  const activeFilters = useSelector(
    getActiveFilters(Object.keys(advancedKeys))
  );

  const handleKolGroup = (key, value) => {
    dispatch(
      setFilters({
        kol: false,
        [key]: value,
      })
    );
  };

  const handleKol = value => {
    if (value) {
      dispatch(
        setFilters({
          kol: true,
          threeYearHIndexAverage: 300,
          totalPublications: 10,
        })
      );
    } else {
      dispatch(
        setFilters({
          kol: false,
          threeYearHIndexAverage: 0,
          totalPublications: 0,
        })
      );
    }
  };

  return (
    <FilterGroup
      icon='settings'
      title='Advanced / KOL'
      activeKeys={activeFilters}
      sectionKey='advanced'
    >
      <>
        <Filter
          filterType='switch'
          filterId='kol'
          onChange={handleKol}
          disabled={loading}
        >
          Key Opinion Leaders Only
        </Filter>
        <Filter
          filterType='token'
          filterId={advancedKeys.journal.key}
          placeholder='Journals or Preprint Repository'
          helpText='Filter results by journals or preprint repositories relating to your search'
          options={journals}
          minInputSize={3}
          disabled={loading}
        />
        <Filter
          filterType='number'
          filterId={advancedKeys.totalPublications.key}
          placeholder='Min. Publications'
          min={0}
          onChange={value => handleKolGroup('totalPublications', value)}
          disabled={loading}
        />
        <Filter
          filterType='number'
          filterId={advancedKeys.totalCollaboratorsOverall.key}
          placeholder='Min. Collaborators'
          min={0}
          disabled={loading}
        />
        <Filter
          filterType='number'
          filterId={advancedKeys.totalFirstNamedAuthorCount.key}
          placeholder='First Author Positions'
          data-testid='first-author'
          min={0}
          disabled={loading}
        />
        <Filter
          filterType='number'
          filterId={advancedKeys.totalLastNamedAuthorCount.key}
          placeholder='Last Author Positions'
          data-testid='last-author'
          min={0}
          disabled={loading}
        />
        <Filter
          filterType='number'
          filterId={advancedKeys.totalGrants.key}
          placeholder='Min. Total Grants'
          min={0}
          disabled={loading}
        />
        <Filter
          filterType='number'
          filterId={advancedKeys.threeYearHIndexAverage.key}
          min={0}
          placeholder='Min. Research Impact (3 Year Avg)'
          helpText={<ImpactScoreHelp />}
          onChange={value => handleKolGroup('threeYearHIndexAverage', value)}
          disabled={loading}
        />
        <Filter
          filterType='checkbox'
          filterId={advancedKeys.firstAuthor.key}
          filterGroup='namedAuthor'
          disabled={loading}
        >
          First Named Author
        </Filter>
        <Filter
          filterType='checkbox'
          filterId={advancedKeys.middleAuthor.key}
          filterGroup='namedAuthor'
          disabled={loading}
        >
          Middle Named Author
        </Filter>
        <Filter
          filterType='checkbox'
          filterId={advancedKeys.lastAuthor.key}
          filterGroup='namedAuthor'
          disabled={loading}
        >
          Last Named Author
        </Filter>
      </>
    </FilterGroup>
  );
};

Advanced.propTypes = {
  loading: PropTypes.bool,
};

export default Advanced;
