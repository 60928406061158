import storage from 'services/storage';

export const types = {
  SET_PAGE_SIZE: 'CALENDAR_SET_PAGE_SIZE',
  SET_PAGE_NUMBER: 'CALENDAR_SET_PAGE_NUMBER',
  SET_SORT: 'CALENDAR_SET_SORT',
  SET_FILTER: 'CALENDAR_SET_FILTERS',
  RESTORE_STATE: 'CALENDAR_RESTORE_STATE',
  CLEAR_FILTERS: 'CALENDAR_CLEAR_FILTERS',
};

const persistState = async getState => {
  const state = getState();
  const toSave = JSON.parse(JSON.stringify(state)).calendar;
  await storage.setItem('calendar', toSave);
};

export const clearFilters = async dispatch => {
  await storage.removeItem('calendar');
  dispatch({
    type: types.CLEAR_FILTERS,
  });
};

export const setPageSize = size => (dispatch, getState) => {
  dispatch({
    type: types.SET_PAGE_SIZE,
    payload: { size },
  });
  persistState(getState);
};

export const setPageNumber = number => (dispatch, getState) => {
  dispatch({
    type: types.SET_PAGE_NUMBER,
    payload: { number },
  });
  persistState(getState);
};

export const setSort = sortKey => (dispatch, getState) => {
  dispatch({
    type: types.SET_SORT,
    payload: { sortKey },
  });
  persistState(getState);
};

export const setFilter = (id, value) => (dispatch, getState) => {
  dispatch({
    type: types.SET_FILTER,
    payload: { id, value },
  });
  persistState(getState);
};

export const restoreState = state => ({
  type: types.RESTORE_STATE,
  payload: state,
});
