import storage from 'services/storage';

export const types = {
  SET_SEARCH_REQUEST: 'PURCHASING_SET_SEARCH_REQUEST',
  PERFORM_SEARCH: 'PURCHASING_PERFORM_SEARCH',
  CLEAR_FILTERS: 'PURCHASING_CLEAR_FILTERS',
  SET_FILTER: 'PURCHASING_SET_FILTER',
  SET_FILTER_GROUP: 'PURCHASING_SET_FILTER_GROUP',
  RESET_FILTERS: 'PURCHASING_RESET_FILTERS',
  SET_SORT: 'PURCHASING_SET_SORT',
  SET_PAGE_SIZE: 'PURCHASING_SET_PAGE_SIZE',
  SET_PAGE_NUMBER: 'PURCHASING_SET_PAGE_NUMBER',
  SETUP_EXTERNAL_SEARCH: 'PURCHASING_SETUP_EXTERNAL_SEARCH',
  RESTORE_STATE: 'PURCHASING_RESTORE_STATE',
  DISMISS_NOTICE: 'PURCHASING_DISMISS_NOTICE',
};

const persistState = async (dispatch, getState) => {
  const state = getState();
  await storage.setItem('purchasing', state.purchasing);
};

export const setSearchRequest = searchRequest => async dispatch => {
  await dispatch({
    type: types.SET_SEARCH_REQUEST,
    payload: { ...searchRequest },
  });
  await dispatch(persistState);
};

export const performSearch = (
  request,
  options = { newSearch: false, useLastSuccessfulSearch: false }
) => ({
  type: types.PERFORM_SEARCH,
  payload: { request, ...options },
});

export const setupExternalSearch = filters => ({
  type: types.SETUP_EXTERNAL_SEARCH,
  payload: { filters },
});

export const clearFilters = {
  type: types.CLEAR_FILTERS,
};

export const resetFilters = {
  type: types.RESET_FILTERS,
};

export const setFilter = (id, value) => ({
  type: types.SET_FILTER,
  payload: { id, value },
});

export const setFilterGroup = (group, id, value) => ({
  type: types.SET_FILTER_GROUP,
  payload: { group, id, value },
});

export const setSort = (sort, postSearch) => (dispatch, getState) => {
  dispatch({
    type: types.SET_SORT,
    payload: { sort },
  });

  dispatch(performSearch(postSearch, { useLastSuccessfulSearch: true }));
};

export const setPageSize = (size, postSearch) => (dispatch, getState) => {
  dispatch({
    type: types.SET_PAGE_SIZE,
    payload: { size },
  });

  dispatch(performSearch(postSearch, { useLastSuccessfulSearch: true }));
};

export const setPageNumber = (number, postSearch) => (dispatch, getState) => {
  dispatch({
    type: types.SET_PAGE_NUMBER,
    payload: { number },
  });

  dispatch(performSearch(postSearch, { useLastSuccessfulSearch: true }));
};

export const restoreState = state => ({
  type: types.RESTORE_STATE,
  payload: state,
});

export const dismissNotice = async dispatch => {
  await dispatch({
    type: types.DISMISS_NOTICE,
  });
  await dispatch(persistState);
};
