import PropTypes from 'prop-types';

import { TfaMessageContainer } from './styled';

const TfaEnrollmentMessage = ({ onMoreClick }) => {
  const emptyLink = null;
  return (
    <TfaMessageContainer>
      <p>
        For security and data protection compilance, SciLeads requires
        two-factor authentication.
      </p>
      <p>
        We recommend you use the Google Authenticator app on your phone to
        complete this process.
      </p>
      <p>
        To enrol please scan the QR code with your authenticator app, then
        submit the 6-digit code it generates.
      </p>
      <div className='more-container'>
        <a href={emptyLink} rel='noopener noreferrer' onClick={onMoreClick}>
          more..
        </a>
      </div>
    </TfaMessageContainer>
  );
};

TfaEnrollmentMessage.propTypes = {
  onMoreClick: PropTypes.func.isRequired,
};

export default TfaEnrollmentMessage;
