import { types } from 'actions/other';

const defaultState = {
  breadcrumb: [],
  restoringState: true,
  reloadCrmConnections: false,
};

const REDUCER = {
  [types.ADD_BREADCRUMB]: (state, action) => ({
    ...state,
    breadcrumb: [...state.breadcrumb, action.payload.crumb],
  }),
  [types.SET_BREADCRUMB]: (state, action) => ({
    ...state,
    breadcrumb: [...action.payload.crumbs],
  }),
  [types.RELOAD_CRM_CONNECTIONS]: (state, action) => ({
    ...state,
    reloadCrmConnections: action.payload,
  }),
  [types.RESTORE_STATE]: (state, action) => {
    if (!action.payload) {
      return {
        ...state,
        restoringState: false,
      };
    }
    const restoredState = JSON.parse(JSON.stringify(action.payload));
    restoredState.restoringState = false;
    return restoredState;
  },
  [types.SET_NEW_TAB_BREADCRUMB]: (state, action) => ({
    ...state,
    newTabBreadcrumb: { ...action.payload.crumb },
  }),
};

const reducer = (state = defaultState, action) => {
  const handler = REDUCER[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
