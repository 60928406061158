import { useState } from 'react';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, Input, Logo, Form } from 'components/common';
import { usePost } from 'hooks';
import { resetUserPassword } from 'services/api';

import EmailSent from './EmailSent';
import SetNewPass from './SetNewPass';
import { Container, Pane, InnerContent } from '../styled';

const ResetPassword = () => {
  const { push } = useHistory();
  const [username, setUsername] = useState('');
  const { search } = useLocation();
  const { code } = qs.parse(search);
  const [{ res: resetRes, loading }, postReset] = usePost({
    url: resetUserPassword,
  });

  const handleLogin = () => {
    push('/login');
  };

  const handleReset = () => {
    postReset({ username });
  };

  if (resetRes) return <EmailSent />;
  if (code)
    return (
      <Container>
        <Logo />
        <Pane>
          <InnerContent>
            <SetNewPass code={code} />
          </InnerContent>
        </Pane>
      </Container>
    );

  return (
    <Container>
      <Logo />
      <Pane>
        <InnerContent>
          <p>Please enter your email address below to reset your password.</p>
          <Form onSubmit={handleReset}>
            <Input
              disabled={loading}
              onChange={setUsername}
              value={username}
              placeholder='Email'
            />
          </Form>
          <Button disabled={loading} onClick={handleReset} type='primary'>
            Reset Password
          </Button>
          <Button disabled={loading} onClick={handleLogin}>
            Go to Login
          </Button>
        </InnerContent>
      </Pane>
    </Container>
  );
};

export default ResetPassword;
