import { types } from 'actions/lists';
import { views } from 'components/Lists/constants';

let defaultState = {
  view: views.researchers,
  modal: '',
  researcher: {
    activeList: {},
    addToTarget: {},
    showMyListsOnly: false,
    includePausedLists: false,
    pageSize: 50,
    pageNumber: 0,
    selectedMembers: {
      include: {},
      exclude: {},
    },
    tableSort: {
      dir: '',
      by: '',
    },
    listMembers: {},
    restoringState: true,
  },
  organisation: {
    activeList: {},
    addToTarget: {},
    showMyListsOnly: false,
    includePausedLists: false,
    pageSize: 50,
    pageNumber: 0,
    selectedMembers: {
      include: {},
      exclude: {},
    },
    tableSort: {
      dir: '',
      by: '',
    },
    listMembers: {},
    restoringState: true,
  },
};

const baseReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_VIEW:
      return {
        ...state,
        view: action.payload,
      };
    case types.SET_MODAL:
      return {
        ...state,
        modal: action.payload.modal,
      };
    case types.RESTORE_STATE: {
      if (!action.payload) {
        return {
          ...state,
          restoringState: false,
        };
      }

      const restoredState = JSON.parse(JSON.stringify(action.payload));
      restoredState.restoringState = false;
      return restoredState;
    }
    default:
      return state;
  }
};

const REDUCER = {
  [types.SET_ACTIVE_LIST]: (state, action) => {
    if (!action.payload.list?.id) {
      return {
        ...state,
        activeList: action.payload.list,
        selectedMembers: { exclude: {}, include: {} },
        listMembers: {},
        pageSize: 50,
        pageNumber: 0,
      };
    }
    return {
      ...state,
      activeList: action.payload.list,
      pageSize: 50,
      pageNumber: 0,
    };
  },
  [types.SET_SHOW_MY_LISTS_ONLY]: (state, action) => ({
    ...state,
    showMyListsOnly: action.payload.show,
  }),
  [types.SET_INCLUDE_PAUSED_LISTS]: (state, action) => ({
    ...state,
    includePausedLists: action.payload.include,
  }),
  [types.SET_SELECTED_MEMBERS]: (state, action) => ({
    ...state,
    selectedMembers: {
      ...action.payload.selected,
    },
  }),
  [types.SET_PAGE_SIZE]: (state, action) => ({
    ...state,
    pageSize: action.payload.size,
  }),
  [types.SET_PAGE_NUMBER]: (state, action) => ({
    ...state,
    pageNumber: action.payload.number,
  }),
  [types.SET_SORT]: (state, action) => {
    let sort = { ...state.tableSort };

    if (action.payload.sortKey === sort.by) {
      if (sort.dir === 'asc') {
        sort = {
          by: action.payload.sortKey,
          dir: 'desc',
        };
      } else {
        sort = {
          by: '',
          dir: '',
        };
      }
    } else {
      sort = {
        by: action.payload.sortKey,
        dir: 'asc',
      };
    }
    return {
      ...state,
      tableSort: sort,
    };
  },
  [types.SET_ADD_TO_TARGET]: (state, action) => ({
    ...state,
    addToTarget: action.payload.list,
  }),
  [types.SET_LIST_MEMBERS]: (state, action) => ({
    ...state,
    listMembers: { ...action.payload.members },
  }),
};

const reducer = (state = defaultState, action) => {
  let newState = baseReducer(state, action);
  const handler = REDUCER[action.type];
  const view = state.view;
  newState = {
    ...newState,
    [view]: handler ? handler(newState[view], action) : newState[view],
  };

  return newState;
};

export default reducer;
