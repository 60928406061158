import { Checkbox as AntCheckbox } from 'antd';

import { Tooltip } from 'components/common';

const Checkbox = ({ helpText, onChange, ...rest }) => {
  const handleChange = e => {
    onChange && onChange(e.target.checked);
  };

  return (
    <Tooltip title={helpText} placement='right'>
      <AntCheckbox
        {...rest}
        style={{ marginLeft: '0px' }}
        onChange={handleChange}
      />
    </Tooltip>
  );
};

Checkbox.defaultProps = {
  'data-testid': 'checkbox',
};

export default Checkbox;
