import { useEffect } from 'react';
import { Alert, Modal as AntModal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { usePost, usePut } from 'hooks';
import { Input, Select, Detail, Loader } from 'components/common';
import { sanityCheckAlert, userAlerts } from 'services/api';
import { getAlertToEdit } from 'selectors/alerts';
import { setAlertToEdit } from 'actions/alerts';
import { frequencyOptions } from 'models/frequency';

const EditModal = ({ onUpdateSuccess }) => {
  const dispatch = useDispatch();
  const activeAlert = useSelector(getAlertToEdit);

  const [{ res: success, loading: updating, error }, updateAlert] = usePut({
    url: userAlerts,
  });

  const [
    { loading: sanityCheckLoading, error: sanityError },
    getUserAlerts,
  ] = usePost({
    url: sanityCheckAlert,
  });

  useEffect(() => {
    getUserAlerts({
      ...activeAlert,
      searchFilters: {
        lists: activeAlert.lists,
        excludeLists: activeAlert.excludeLists,
        organisationLists: activeAlert.organisationLists,
        organisationExcludeLists: activeAlert.organisationExcludeLists,
      },
      nestedQueries: activeAlert.nestedQueries
        ? [...JSON.parse(activeAlert.nestedQueries)]
        : [],
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (success) {
      onUpdateSuccess();
    }
  }, [success, onUpdateSuccess, dispatch]);

  const handleEditChange = key => value => {
    const updatedAlert = {
      ...activeAlert,
      [key]: value,
    };

    dispatch(setAlertToEdit(updatedAlert));
  };

  const handleCancel = () => {
    dispatch(setAlertToEdit());
  };

  const handleUpdate = () => {
    const frequency = frequencyOptions.find(
      freq => freq.value === activeAlert.frequency
    ).enumValue;
    updateAlert({
      ...activeAlert,
      lists: activeAlert.lists?.map(l => l.id) || [],
      excludeLists: activeAlert.excludeLists?.map(l => l.id) || [],
      organisationLists: activeAlert.organisationLists?.map(l => l.id) || [],
      organisationExcludeLists:
        activeAlert.organisationExcludeLists?.map(l => l.id) || [],
      frequency,
    });
  };

  if (!activeAlert) return null;

  const isValid =
    activeAlert.name && activeAlert.monthlyKeywords && activeAlert.frequency;

  return (
    <AntModal
      open
      title='Edit Search'
      onCancel={handleCancel}
      okText='Save'
      onOk={handleUpdate}
      okButtonProps={{
        disabled: updating || !isValid || sanityError,
        'data-testid': 'edit-save-button',
      }}
      cancelButtonProps={{
        disabled: updating,
        'data-testid': 'edit-cancel-button',
      }}
    >
      {sanityCheckLoading ? (
        <Loader />
      ) : sanityError ? (
        <div />
      ) : (
        <div>
          <Detail label='Search Name'>
            <Input
              showCount
              maxLength={50}
              disabled={updating}
              multiline
              value={activeAlert.name}
              onChange={handleEditChange('name')}
            />
          </Detail>
          <Detail label='Alert Frequency'>
            <Select
              disabled={updating}
              style={{ width: '100%' }}
              value={activeAlert.frequency}
              onChange={handleEditChange('frequency')}
              options={frequencyOptions}
            />
          </Detail>
        </div>
      )}

      {sanityError && (
        <Alert
          message={'This is not a valid search and cannot be edited.'}
          type='error'
        />
      )}
      {error && <Alert message={error.message.message} type='error' />}
    </AntModal>
  );
};

export default EditModal;
